import React, { useState } from 'react';
import { Col, Fade, Row } from 'reactstrap'
import Searchbox from './Searchbox';
import { useSelector } from 'react-redux';
import { getHomeState } from '../../features/homepageSlice';

function SearchBar(props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const data = useSelector(getHomeState);
    return (
        <Fade>
            <Row className="mb-3 mx-auto">
                <Col>
                    <input type="search" onFocus={toggle} placeholder={data.testo88} />
                </Col>
            </Row>

            {isOpen &&
                <Searchbox close={() => toggle()} />
            }
        </Fade>
    )
}

export default SearchBar