import React, { useState, useEffect } from 'react';
import { Col, Fade, Row, Dropdown , DropdownToggle, DropdownMenu, DropdownItem, Collapse } from 'reactstrap'
import SearchBar from './SearchBar';
import { getUser, getUserState } from '../../features/userSlice'
import getCookie from '../../functions/getCookie'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import Map from './icons/Map2.svg'
import Order from './icons/order.svg'
import GoogleMapReact from 'google-map-react';
import MapsPin from './MapsPin'
import { useSelector, useDispatch } from 'react-redux';
import { getGeolocationState } from '../../features/geolocationSlice'
import { getBootState } from '../../features/bootstrapSlice'
import { getHomeState } from '../../features/homepageSlice';
import { getDistance, getDistanceFormat } from '../../functions/getDistance';

function Favorite(props) {
    //dati di geolocalizzazione
    const geo = useSelector(getGeolocationState)
    const bootData = useSelector(getBootState)
    const homeData = useSelector(getHomeState);

    //hooks centro della mappa
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [sort, setSort] = useState('default');

    //gestore del centro della mappa
    const onClickMaps = (elmn, childProps) => {
        setLat(parseFloat(elmn.split('-')[0]))
        setLng(parseFloat(elmn.split('-')[1]))
    }

    //
    const [isOpen, setIsOpen] = useState(false);
    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    }
    const [dropdownOpen, setdropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setdropdownOpen(!dropdownOpen);
    }

    const [category, setCategory] = useState('All')
    //const data = useSelector(getHomeState);

    //aggiorno i dati dell'utente
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);
    const userData = useSelector(getUserState);

    //prendo le categorie dei posti visualizzati
    var categorie = [];
    if(!userData.loading) {
        userData.data.utente.preferiti.forEach(element => {
            if(categorie.indexOf(element.categoria) < 0) {
                categorie.push(element.categoria)
            }
        });
    }

    //trasformo l'array di dati ricevuti
    var elementList = []
    if(!userData.loading){
        var temp = userData.data.utente.preferiti.slice();
        switch(sort){
            case'asc':
                elementList = temp.sort(function(a, b) {
                    if ( a.nome < b.nome ){
                        return -1;
                    }
                    if ( a.nome > b.nome ){
                        return 1;
                    }
                    return 0;
                });
            break
            case'near':
                //usare geolocal
                elementList = temp.sort(function(a, b) {
                    if ( getDistance(a.latitudine, a.longitudine, geo.lat, geo.lng) < getDistance(b.latitudine, b.longitudine, geo.lat, geo.lng) ){
                        return -1;
                    }
                    if ( getDistance(a.latitudine, a.longitudine, geo.lat, geo.lng) > getDistance(b.latitudine, b.longitudine, geo.lat, geo.lng) ){
                        return 1;
                    }
                    return 0;
                });
            break
            case'rank':
                //momentaneamente da nascondere
            break
            default:
                elementList = temp
            break
        }
    } else{

    }

    return (
        <>
            {document.cookie.match('connesso')
            ?
                <>
                    {!userData.loading
                        ?
                        <Fade>
                            {userData.data.utente.preferiti
                            ?
                                <>
                                    <SearchBar />
                                    {!(elementList.length === 0) &&
                                        <div className="testimonial-group container mt-2 pb-2">
                                            <Row className="text-center cat-scroll" style={{ margin: "0px" }}>
                                                {category === 'All'
                                                ?
                                                    <div onClick={() => setCategory('All')} className="col btn-category" style={{ backgroundColor: "#c6b39a" }}><span className="m-0">All</span></div>
                                                :
                                                    <div onClick={() => setCategory('All')} className="col btn-category"><span className="m-0">All</span></div>
                                                }
                                                {categorie && categorie.map((categoria, index) => {
                                                    return (
                                                    categoria === category
                                                    ? 
                                                        <div key={index} onClick={() => setCategory(categoria)} style={{ backgroundColor: "#c6b39a" }} className="col btn-category"><span className="m-0">{categoria}</span></div>
                                                    :
                                                        <div key={index} onClick={() => setCategory(categoria)} className="col btn-category"><span className="m-0">{categoria}</span></div>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    }
                                
                                    <div className="pt-3 pl-4 pr-4 pb-2 " style={{ backgroundColor: "#fff" }}>
                                        
                                        {!(elementList.length === 0) &&
                                            <>
                                                <Row className="mb-4">
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <small className="text-dark" onClick={toggleCollapse}>{homeData.testo142}</small>
                                                                <img className="ml-2" width="16" src={Map} alt=""/>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="d-flex float-right align-items-center">
                                                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                                <DropdownToggle tag="div">
                                                                    <small className="text-dark">{homeData.testo139}</small>
                                                                    <img className="ml-2" width="16" src={Order} alt=""/>
                                                                </DropdownToggle >
                                                                <DropdownMenu style={{minWidth:"auto"}}>
                                                                        <DropdownItem onClick={() => setSort('asc')} tag="span" className="p-3 label-lang">
                                                                            {homeData.testo140}
                                                                        </DropdownItem>
                                                                        <DropdownItem onClick={() => setSort('near')} tag="span" className="p-3 label-lang">
                                                                            {homeData.testo141}
                                                                        </DropdownItem>
                                                                        {/*<DropdownItem tag="span" className="p-3 label-lang">
                                                                            Più votato
                                                                        </DropdownItem>*/}
                                                                </DropdownMenu>
                                                            </Dropdown >
                                                            
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Collapse isOpen={isOpen}>
                                                    <div style={{ height: '40vh', width: '100%' }} className="pb-4">
                                                        {bootData.posto_master &&
                                                            <GoogleMapReact
                                                                bootstrapURLKeys={{ key:"AIzaSyAUPWWxtpZHGRnLPjFBF1O2XvYFxG4jxjs"}}
                                                                defaultCenter={{ lat: parseFloat(bootData.posto_master.latitudine), lng: parseFloat(bootData.posto_master.longitudine) }}
                                                                center={{ lat: lat !== 0 ? lat : parseFloat(bootData.posto_master.latitudine), lng: lng !== 0 ? lng : parseFloat(bootData.posto_master.longitudine) }}
                                                                defaultZoom={12}
                                                                onChildClick={onClickMaps}
                                                            >
                                                            {userData.data.utente.preferiti.map((posto, index) =>{
                                                                return(
                                                                (category === 'All' || category === posto.categoria) &&
                                                                    <MapsPin
                                                                        index={index}
                                                                        key={posto.latitudine + "-" + posto.longitudine}
                                                                        lat={posto.latitudine}
                                                                        lng={posto.longitudine}
                                                                        text={posto.nome}
                                                                    />
                                                                )
                                                            })}
                                                            </GoogleMapReact>
                                                        }
                                                    </div>
                                                </Collapse>
                                            </>
                                        }

                                        {elementList.map((posto, index) =>{
                                            return(
                                            (category === 'All' || category === posto.categoria) &&
                                                <>
                                                <Link to={{pathname: "/place/" + posto.id, query: props.location.query, state: { prevPath: '/favorite'} }} key={index} className="row card-list">
                                                    <Col className="border-bottom p-0 ml-2 mr-2">
                                                        <div className="card-img w-auto" style={{ backgroundImage: `url('${posto.copertina}')` }}>
                                                            {/*<div className="row m-2 rate-box">
                                                                <div className="col-auto pr-1 mx-auto fas fa-star"></div>
                                                                <div className="col-auto p-0">N.D</div>
                                                            </div>*/}
                                                            <div className="row m-2 rate-box">
                                                                <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                                                <div className="col-auto p-0">{posto.cuori}</div>
                                                            </div>
                                                        </div>
                                                        {posto.orari 
                                                            ?
                                                            <div className="row mt-2 mr-1 float-right text-dot"><div className="mr-2">Aperto</div><div className="green-dot mb-auto mt-auto"></div></div>
                                                            :
                                                            <div className="row mt-2 mr-1 float-right text-dot"><div className="mr-2">Chiuso</div><div className="red-dot mb-auto mt-auto"></div></div>
                                                        }
                                                        <h4 className="card-text mt-2 mb-0">{posto.nome}</h4>
                                                        <p className="card-subtext mb-0" style={{fontSize: '12px'}}>{posto.categoria}</p>
                                                        <p className="mb-1"><span className="price-card mr-3">{posto.costo ? posto.costo.replace(/&euro;/g,"€") : ""}</span><span className="distance-card">{posto.latitudine !== "0" && posto.longitudine !== 0 ? getDistanceFormat(posto.latitudine, posto.longitudine, geo.lat, geo.lng) : ""}</span></p>
                                                    </Col>
                                                </Link>
                                                </>
                                            )
                                        })}

                                        {elementList.length === 0 &&
                                            <div>{homeData.testo137}</div>
                                        }
                                    </div>
                                </>
                            :
                                <Redirect to="/" />
                            }
                        </Fade>
                    :
                        <></>
                    }
                </>
            :
                <Redirect to="/login" />
            }
        </>
    )
}

export default Favorite