function getDistance (latA, lonA, latB, lonB) {
    /* Definisce le costanti e le variabili */
    var R = 6371;
    var pigreco = 3.1415927;
    var lat_alfa, lat_beta;
    var lon_alfa, lon_beta;
    var fi;
    var p, d;
    /* Converte i gradi in radianti */
    lat_alfa = pigreco * latA / 180;
    lat_beta = pigreco * latB / 180;
    lon_alfa = pigreco * lonA / 180;
    lon_beta = pigreco * lonB / 180;
    /* Calcola l'angolo compreso fi */
    fi = Math.abs(lon_alfa - lon_beta);
    /* Calcola il terzo lato del triangolo sferico */
    p = Math.acos(Math.sin(lat_beta) * Math.sin(lat_alfa) + 
    Math.cos(lat_beta) * Math.cos(lat_alfa) * Math.cos(fi));
    /* Calcola la distanza sulla superficie 
    terrestre R = ~6371 km */
    d = p * R;
    return(d); //in km
}

function getDistanceFormat (latA, lonA, latB, lonB) {
    /* Definisce le costanti e le variabili */
    var R = 6371;
    var pigreco = 3.1415927;
    var lat_alfa, lat_beta;
    var lon_alfa, lon_beta;
    var fi;
    var p, d;
    /* Converte i gradi in radianti */
    lat_alfa = pigreco * latA / 180;
    lat_beta = pigreco * latB / 180;
    lon_alfa = pigreco * lonA / 180;
    lon_beta = pigreco * lonB / 180;
    /* Calcola l'angolo compreso fi */
    fi = Math.abs(lon_alfa - lon_beta);
    /* Calcola il terzo lato del triangolo sferico */
    p = Math.acos(Math.sin(lat_beta) * Math.sin(lat_alfa) + 
    Math.cos(lat_beta) * Math.cos(lat_alfa) * Math.cos(fi));
    /* Calcola la distanza sulla superficie 
    terrestre R = ~6371 km */
    d = p * R;
    if (d >= 1) {
        return d.toFixed(1) + " km";
    }else if(d < 1){
        return (d.toFixed(3)*1000) + " m";
    }
}

export { getDistance, getDistanceFormat }
