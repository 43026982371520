import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Card, Container, Spinner, Button, Fade } from 'reactstrap';
import { getQuestionario, getQuestState, sendQuestionario, setAnswers, setSubAnswers } from '../../features/questSlice';
import Domanda from './Domanda';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';
import { getHomeState } from '../../features/homepageSlice';

function Questionario(props){

    const homeData = useSelector(getHomeState);

    const [lang, setLang] = useState("");
    const [send, setSend] = useState(false);

    const questparams = props.match.params.decode;

    let params = atob(questparams);

    let subindex = 0;

    let history = useHistory();

    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    let query = parseQuery(params); 

    //console.log(query);

    const dispatch = useDispatch();
    useEffect(() =>{
        setLang(query.q_lingua);
        dispatch(getQuestionario('/get-quest/'+questparams));
    }, []);

    const dataQuest = useSelector(getQuestState);


    useEffect(() =>{
        let answers = document.getElementsByName(query.q_doid);
        if(query.q_dotipo === "RV"){

            for(let i = 0; i <answers.length; i++){
                if(answers[i].id === query.q_opzione){
                    answers[i].setAttribute("class", "btn-valutazione active");
                    dispatch(setAnswers({name: "voto"+query.q_doid, vote: query.q_opzione}));
                    dispatch(setSubAnswers({id: query.q_doid, vote: query.q_opzione, qt: "RV"}));
                }
            }
        }else if(query.q_dotipo === "RO" || query.q_dotipo === "RM"){

            for(let i = 0; i <answers.length; i++){
                if(answers[i].id === query.q_opzione){
                    answers[i].setAttribute("checked", "true");
                    dispatch(setAnswers({name: "opzione"+query.q_doid, vote: query.q_opzione}));
                    dispatch(setSubAnswers({id: query.q_doid, vote: query.q_opzione, qt: "RO", textAnswer: 'ck'}));
                }
            }
        }else {

            for(let i = 0; i <answers.length; i++){
                if(answers[i].id === query.q_opzione){
                    answers[i].setAttribute("checked", "true");
                    dispatch(setAnswers({name: "opzione"+query.q_doid, vote: query.q_opzione}));
                    dispatch(setSubAnswers({id: query.q_doid, vote: query.q_opzione, qt: "SN", textAnswer: ''}));
                }
            }
        }

    }, [dataQuest.loading === false]);

    //let decode = atob(questparams);
    
    //let params = parseQuery(decode);

    const sendQuest = () => {
        const data = {data: 
            { utente: dataQuest.data.utid, 
              questionario: parseInt(dataQuest.data.quid), 
              start: dataQuest.data.start, 
              invio: dataQuest.data.invio,
              compilato: typeof query.q_compilato !== 'undefined' ? query.q_compilato : 0
             }}
             
        data.data = Object.assign(data.data, props.answers);
        dispatch(sendQuestionario(data));
        setSend(true);
    }

    const changeLang = e =>{
        params = params.replace(lang, ""+e.target.value);
        setLang(e.target.value);
        let querystr = btoa(params);
        
        dispatch(getQuestionario('/get-quest/'+querystr));
        history.push("/questionario/"+querystr);
    }

    if(send){
        return (
            <Fade>
                <div className="text-center w-100" style={{position: "absolute", top: "30%"}}>
                    <h1>{homeData.testo163}</h1><br/>
                    <a href={dataQuest.data.landing}><Button className="btn-light mt-2">{homeData.testo165}</Button></a>
                </div>
            </Fade>
        );
    }else if(!dataQuest.loading && dataQuest.data.iscompilato == 1) {
        return (
            <Fade>
                <div className="text-center w-100" style={{position: "absolute", top: "30%"}}>
                    <h1>{homeData.testo163}</h1><br/>
                    <a href={dataQuest.data.landing}><Button className="btn-light mt-2">{homeData.testo165}</Button></a>
                </div>
            </Fade>
        );
    }else if(dataQuest.loading){
        return (
            <div className="loading">
                <Spinner className="spinner" color="danger" />
            </div> 
        );
    }else if(!dataQuest.loading && dataQuest.error === ''){
        return(
            <Container style={{marginTop: "2%"}}>
                <div style={{color: "#475F7B"}} className="row m-1">
                    <option value="IT" style={{fontWeight: lang ==='IT' ? "bold" : "", cursor: "pointer"}} onClick={changeLang} >ITA</option>
                    {dataQuest.data.lingue.map((lingua) => {
                        return <option value={lingua} style={{fontWeight: lang === lingua ? "bold" : "", cursor: "pointer"}} onClick={changeLang} >{lingua}</option>
                    })}
                </div>
                <Card style={{marginBottom: "4%"}}>
                <form className="questionario" onSubmit={sendQuest}>
                    <div className="questionario" style={{padding: "5%"}}>
                        <div className="img-fluid" style={{float: "right", padding:"2%"}}>
                            <img style={{ maxHeight:"134px", maxWidth:"134px"}} src={dataQuest.data.logo} />
                        </div>
                        <h4 style={{fontWeight: "bold", fontSize: "18px", color: "#475F7B"}}>{dataQuest.data.titolo}</h4>
                        <div style={{fontSize: "18px", color: "#475F7B", paddingTop:"8%", paddingBottom: "1%"}}>
                            {dataQuest.data.descrizione === null ? <></> : Parser(dataQuest.data.descrizione)}
                        </div>
                        <hr style={{border: "1px solid #E3E7EA"}}></hr>
                            {dataQuest.data.domande.map((domanda, index) =>{
                            /*return fetchQuestions(domanda, index, updateField, updateTextState);*/
                                return <Domanda dotipo={query.q_dotipo} doid={query.q_doid} opzione={query.q_opzione} domanda={domanda} index={index} subindex={subindex} />
                            })}
                    </div>
                    <div style={{marginBottom: "142px"}} className="d-flex justify-content-center">
                        <button onClick={sendQuest} name="invia" className="btn btn-invia" type="button">Invia</button>
                    </div>
                </form>
              </Card>
            </Container>
        );
    }else if(!dataQuest.error !==''){
        return "Error";
    }
}

function mapStateToProps(state){
    
    return { answers: state.quest.answers, questparams: state.quest.questParams }
}

export default connect(mapStateToProps)(Questionario);