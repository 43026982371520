import React, { useEffect ,useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";
import { getUser } from '../../features/userSlice';
import getCookie from '../../functions/getCookie';
import { Row, Col, Fade, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import ServicesBar from './ServicesBar';
import PlacesCardsContainer from './PlacesCardsContainer';
import PlacesMapContainer from './PlacesMapContainer';
import FiltersContainer from './FiltersContainer';
import Categorielist from './Categorielist';
import gridIcon from './icons/grid_icon.svg';
import mapIcon from '../Mobile/icons/Map2.svg';
import SearchBar from "./SearchBar";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Home({ homeData, homeStatus, geo, bootData, userData}){

    //filters and searchbar
    const [searchQuery, setSearchQuery] = useState('');
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [placesState, setPlacesState] = useState({});
    //service
    const [servizioId, setServizioId] = useState(false)
    //place map
    const toggleModal = () => setShowMap(false);

    const dispatch = useDispatch();


    //servizio se arrivo dal qrcode
    const params = useParams();
    const id = params.id;
    if(!'id' in params) {
        id = false;
    }
    useEffect(() =>{
        setServizioId(id)
    }, [homeData])
    if('id' in params) {
        window.history.replaceState(null, "Weforguest", "/")
    }


    let places = {}
    if(!homeStatus.loading && homeData.hasOwnProperty('categorie')) {
        homeData.categorie.forEach((categoria) =>{
            places = Object.assign(places, {[categoria.nome]: categoria.posti})
        })

        places = Object.assign(places, {all: homeData.posti.items})
    }

    useEffect(() =>{
        setPlacesState(places);
    }, [homeData])

    const filterPlaces = (query, categories) => {
        if (!query && categories.length === 0) {
            return placesState["all"];
        }

        if(query && categories.length === 0){
            return placesState["all"].filter((place) =>{
                const postName = place.nome.toLowerCase();
                return postName.includes(query);
            });
        }

        let filteredPlaces = []
        categories.forEach((category) => {
            Array.prototype.push.apply(filteredPlaces, placesState[category]);
        })

        return filteredPlaces.filter((place) =>{
            const postName = place.nome.toLowerCase();
            return postName.includes(query);
        });
    }

    const checkFilter = (name) => {
        if(categoryFilter.includes(name)){
            document.getElementById(name).nextElementSibling.classList.toggle('elchecked');
            setCategoryFilter(categoryFilter.filter(item => item !== name))
        }else {
            document.getElementById(name).nextElementSibling.classList.toggle('elchecked');
            setCategoryFilter(oldCategories => [...oldCategories, name]);
        }
    }

    const removeFilters = () => {
        categoryFilter.forEach(element => {
            document.getElementById(element).checked = false;
            document.getElementById(element).nextElementSibling.classList.toggle('elchecked');
        });
        setCategoryFilter([]);
    }

    //aggiorno l'utente in caso di refresh
    useEffect(() => {
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);


    if(!homeStatus.loading){
        const filteredPlaces = filterPlaces(searchQuery, categoryFilter);

        return(
            <>
            <Fade>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3">
                            <FiltersContainer servizioId={servizioId} setServizioId={setServizioId} userData={userData} statusHome={homeStatus} homeData={homeData} bootData={bootData} searchQuery={searchQuery} setSearchQuery={setSearchQuery} checkFilter={checkFilter} removeFilters={removeFilters} categoryFilter={categoryFilter} />
                        </div>
                        <div className="col-9 bg-white pt-4" style={{overflowX: 'auto', height: '90vh'}}>
                            <div  className="px-3 mb-5">
                                <ServicesBar homeData={homeData} servizioId={servizioId} setServizioId={setServizioId}/>
                            </div>
                            <Row className="justify-content-between">
                                <Col>
                                    <div className="ml-3 home-text">{homeData.testo118}</div>
                                </Col>
                                <Col className={"col-3"}>
                                    {categoryFilter.length === 0 ? <></> :
                                        <div style={{background: "rgb(198, 179, 154, 0.4)", borderRadius: "10px", padding: "4px", width:"fit-content"}} className="d-flex ml-4 pointer" onClick={removeFilters}>
                                            <span className="mr-1"><Button close/></span> {homeData.testo173}
                                        </div>
                                    }
                                </Col>
                            </Row>


                                <div className="align-items-center row" style={{marginLeft: "auto"}}>
                                    <div className="col mt-2 cat-scroll scrollmenu" style={{boxShadow:"0 2px 10px 0 rgba(181, 181, 181, 0.2)", borderRadius:"4px", paddingLeft:"1px", paddingRight:"2px"}}>{homeData.categorie &&
                                        <Categorielist homeData={homeData} checkFilter={checkFilter}/>
                                    }</div>
                                    <div className="col-auto"><SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} /></div>
                                    <div className="col-auto"><img className="places-submenu" onClick={() => setShowMap(false)} src={gridIcon} alt=""></img></div>
                                    <div className="col-auto"><img className="places-submenu" onClick={() => setShowMap(true)} src={mapIcon} width={25} alt=""></img></div>
                                </div>

                                {filteredPlaces &&
                                    <Fade>
                                        <PlacesCardsContainer homeData={homeData} places={filteredPlaces} geo={geo}/>
                                    </Fade>
                                }

                                {!showMap &&  !filteredPlaces &&
                                    <div className="d-flex justify-content-center aligni-items-center"><div>Nessun risultato</div></div>
                                }
                        </div>
                    </div>
                </div>

                <Modal fade isOpen={showMap} centered toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Mappa</ModalHeader>
                    <ModalBody>
                        <PlacesMapContainer data={bootData} places={filteredPlaces} />
                    </ModalBody>
                </Modal>

            </Fade>

            {bootData.posto_master.whatsapp !== "" && bootData.posto_master.whatsapp_floating === "1" &&
                <a href={bootData.posto_master.whatsapp} target='_blank' style={{position: 'fixed', bottom: '0'}} className="m-2"><img src={bootData.posto_master.whatsapp_icon} width="50"/></a>
            }

            </>
        )
    }else{
        return(
            <div style={{position: "absolute", top:"50%"}} className="d-flex justify-content-center h-100">
                <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

export default Home;