import React, { useState } from 'react';
import { UncontrolledPopover, PopoverHeader } from 'reactstrap'

function MapsPin(props) {
    
    const [info, setInfo] = useState(false)

    const toggle = () => setTimeout(function(){ setInfo(!info); }, 300);
    //maps pin
    //const K_WIDTH = 40;
    //const K_HEIGHT = 40;
    /*
    const greatPlaceStyle = {
        // initially any map object has left top corner at lat lng coordinates
        // it's on you to set object origin to 0,0 coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
      
        
        borderRadius: '50%',
        border: '8px solid blue',
        width: '8px',
        height: '8px'
    };
    */

    return (
        <>
            <div>
                <div
                    id={"pop"+props.index}
                    className="pin bounce"
                    style={{ backgroundColor: 'red', cursor: 'pointer' }}
                    title={props.text}
                >
                    <UncontrolledPopover trigger="legacy" placement="top" isOpen={info} target={"pop"+props.index} toggle={toggle}>
                        <PopoverHeader>{props.text}</PopoverHeader>
                    </UncontrolledPopover>
                </div>
                <div className="pulse" />
            </div>
        </>
    )
}

export default MapsPin