import React, { useState, useEffect } from 'react';
import '../../mobile.css'
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import closeNav from '../../functions/closeNav'
import ScrollToTop from '../../functions/ScrollToTop'
import getCookie from '../../functions/getCookie';
import { getHomepage, getHomeStatus, getHomeState } from '../../features/homepageSlice'
import { getUser, getUserState } from "../../features/userSlice";
import { getGeolocationState } from '../../features/geolocationSlice';
import { getBootState } from '../../features/bootstrapSlice';
import Home from './Home'
import Navbar from '../Mobile/Navbar';
import Info from '../Mobile/Info'
import Sidenav from '../Mobile/Sidenav';
import ViewAll from '../Mobile/ViewAll';
import Profile from '../Mobile/Profile';
import Ordini from '../Mobile/Ordini';
import Place from '../Mobile/Place';
import Favorite from '../Mobile/Favorite';
import Itinerario from '../Mobile/Itinerario';
import Cookie from '../Mobile/Cookie';
import OverlayServizio from '../Mobile/OverlayServizio';
import AllServices from './AllServices';
import Login from '../Mobile/Login';
import Checkin from '../Mobile/Checkin'
import Thankyou from '../Mobile/Thankyou';
import ThankyouQuest from '../Mobile/ThankyouQuest';
import Camera from '../Mobile/Camera';
import Questionario from '../Mobile/Questionario';
import ThankyouFastCheckin from '../Mobile/ThankyouFastCheckin';
import ModuloReputazione from '../Mobile/ModuloReputazione';
import Logout from '../Mobile/Logout';
import AnteprimaQuestionario from '../Mobile/AnteprimaQuestionario';
import Unsubscribe from '../Mobile/Unsubscribe';
import Subscribe from '../Mobile/Subscribe';
import Privacy from '../Mobile/Privacy';
import Terms from '../Mobile/Terms';
import Profilazione from '../Mobile/Profilazione';
import Marketing from '../Mobile/Marketing';
import MarketingThird from '../Mobile/MarketingThird';
import Cart from '../Mobile/Cart';



function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AppMobile() {
    
    let query = useQuery();

    const [isOpen, setIsOpen] = useState(false);
    const [cookie/*, setCookie*/] = useState(true);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getHomepage("/get-homepage"));
        dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
    }, []);

    const homeData = useSelector(getHomeState);
    const homeStatus = useSelector(getHomeStatus);
    const geo = useSelector(getGeolocationState);
    const bootData = useSelector(getBootState);
    const userData = useSelector(getUserState);
    
    if(!homeStatus.loading){
        return (
            <>
                <ScrollToTop />
                <Sidenav setOpen={() => setIsOpen(!isOpen)} />
                <Cart />
                <div id="main">
                    <div id="overlay" className="fade show" onClick={() => { closeNav(); setIsOpen(!isOpen) }}></div>
                    <Navbar open={isOpen} setOpen={() => setIsOpen(!isOpen)} />
                    <div className="fade show">
                        {(() => {
                            if(query.get('page') === 'fast'){
                                if(query.get('decode') == "") {
                                    return (
                                        <Redirect to={"/"+query.get('master')+"/fast-checkin.html"} />
                                    )
                                }else {
                                    return (
                                        <Redirect to={"/"+query.get('master')+"/"+query.get('decode')+"/fast-checkin.html"} />
                                    )
                                }
                            }else if(query.get('page') === 'subscribe'){
                                console.log(query.get('email'))
                                if(query.get('email') === null) {
                                    return (
                                        <Redirect to={"/"+query.get('master')+"/subscribe.html"} />
                                    )
                                }else {
                                    return (
                                        <Redirect to={"/"+query.get('master')+"/"+query.get('email')+"/subscribe.html"} />
                                    )
                                }
                            }else if(query.get('page') === 'unsubscribe'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/unsubscribe.html"} />
                                )
                            }else if(query.get('page') === 'profiling'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/profiling.html"} />
                                )
                            }else if(query.get('page') === 'marketing'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/marketing.html"} />
                                )
                            }else if(query.get('page') === 'marketingthird'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/marketingthird.html"} />
                                )
                            }else if(query.get('page') === 'terms'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/terms.html"} />
                                )
                            }else if(query.get('page') === 'cookie'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/cookie.html"} />
                                )
                            }else if(query.get('page') === 'privacy'){
                                return (
                                    <Redirect to={"/"+query.get('master')+"/privacy.html"} />
                                )
                            }else if(query.get('page') === 'info'){
                                return (
                                    <Redirect to={"/info"} />
                                )
                            }else {
                                return (
                                    <Switch>
                                        <Route path="/questionario/:decode" component={Questionario} />
                                        <Route path="/anteprimaquestionario/:decode" component={AnteprimaQuestionario} />
                                        <Route path="/moduloreputazione/:master" component={ModuloReputazione}/>
                                        <Route path="/index.html" component={Home} />
                                        <Route path="/" exact component={Home} />
                                        <Route path="/login" exact component={Login} />
                                        <Route path="/camera" exact component={Camera} />
                                        <Route path="/:master/fast-checkin.html" exact component={Checkin} />
                                        <Route path="/:master/:decode/fast-checkin.html" exact component={Checkin} />
                                        <Route path="/place/:id" exact component={Place} />
                                        <Route path="/thankyoufc" exact component={ThankyouFastCheckin} />
                                        <Route path="/thankyouquest" exact component={ThankyouQuest} />
                                        <Route path="/allservices" exact component={AllServices} />
                                        <Route path="/itinerary/:id" exact component={Itinerario} />
                                        <Route path="/service/:id" exact component={OverlayServizio} />
                                        <Route path="/viewall" exact component={ViewAll} />
                                        <Route path="/profile" exact component={Profile} />
                                        <Route path="/info" exact component={Info} />
                                        <Route path="/:master/info" exact component={Info} />
                                        <Route path="/orders" exact component={Ordini} />
                                        <Route path="/thankyou" exact component={Thankyou} />
                                        <Route path="/favorite" exact component={Favorite} />
                                        <Route path="/logout" exact component={Logout} />
                                        <Route path="/:master/unsubscribe.html" exact component={Unsubscribe} />
                                        <Route path="/:master/subscribe.html" exact component={Subscribe} />
                                        <Route path="/:master/:email/subscribe.html" exact component={Subscribe} />
                                        <Route path="/:master/privacy.html" exact component={Privacy} />
                                        <Route path="/:master/terms.html" exact component={Terms} />
                                        <Route path="/:master/cookie.html" exact component={Cookie} />
                                        <Route path="/:master/profiling.html" exact component={Profilazione} />
                                        <Route path="/:master/marketing.html" exact component={Marketing} />
                                        <Route path="/:master/marketingthird.html" exact component={MarketingThird} />
                                        {/* {!document.cookie.match('connesso') &&
                                            <Redirect to="/login" />
                                        } */}
                                    </Switch>
                                )
                            }
                        })()}

                    </div>
                    
                    {cookie &&
                        <Cookie close={() => localStorage.setItem("cookie", true)} />
                    }


                    {bootData.posto_master.whatsapp !== "" && bootData.posto_master.whatsapp_floating === "1" &&
                        <a href={bootData.posto_master.whatsapp} target='_blank' style={{position: 'fixed', bottom: '0'}} className="m-2"><img src={bootData.posto_master.whatsapp_icon} width="50"/></a>
                    }

                </div>
            </>
        )
    }else {
        return(
            <div className="d-flex justify-content-center align-items-center w-100" style={{height: "80vh"}}>
                <div className="d-flex flex-column align-items-center">
                    {/* <img style={{height:"150px", width:"250px"}} src={Footer} /> */}
                    <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppMobile