import React from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getHomeState } from '../../features/homepageSlice';

function Categorielist(props) {
    const homeData = useSelector(getHomeState);
    return (
        <>
            <div className="container-fluid testimonial-group">
                {/* <Row className="d-flex mx-2">
                    <Col className="col-8 pl-0">
                        <span style={{ fontWeight: "700", fontSize: "1.3em" }}>{homeData.testo117}</span>
                    </Col>
                    <Col className="col-4 text-right">
                        <Link to={{pathname: `/viewall`, query: props}} className="link-color">{homeData.testo97}</Link>
                    </Col>
                </Row> */}
                <Row className="row">
                    {props.sezione && props.sezione.length > 0 && props.sezione.map((posto, index) =>
                        <Link key={index} to={{pathname: '/viewall', query: { item: { items:posto.posti, subcat:posto.subcat }}, state: { prevPath: '/'} }} style={{backgroundColor: 'white', borderRadius: '4px'}} className="col card-custom my-3">
                            <img src={posto.icona} className="icon-card-custom pr-3" height="30" alt=""/>
                            <span className="m-0 pr-2">{posto.nome}</span>
                        </Link>
                        // <Link key={index} to={{pathname: '/viewall', query: { item: { items:posto.posti, subcat:posto.subcat }}, state: { prevPath: '/'} }} style={{backgroundColor: 'white', borderRadius: '4px'}}  className="box_categoria col-8 p-3 px-2 p-0 mb-0 m-1">
                        //     <img src={posto.icona} width="60" height="60" alt=""/>
                        //     <h4 className="card-text text-center text-truncate">{posto.nome}</h4>
                        // </Link>
                    )}
                </Row>
            </div>
        </>
    )
}

export default Categorielist