import React from 'react';
import { Col, Fade, Row } from 'reactstrap'
import Elementi from './OrdiniElementi'
function Ordini({userData, homeData}) {

    return (
                <>
                    <Fade>
                        <h4 className="d-block text-center font-weight-normal pt-4">{homeData.testo110}</h4>
                        {userData.data.utente.prenotazioni.map((prenotazione, index) =>
                            <div key={index} className="mx-4 pb-3 pt-3 border-bottom">
                                <Row className="pb-2">
                                    <Col>{homeData.testo130}</Col>
                                    <Col className="text-right">{prenotazione.nome}</Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col>{homeData.testo131}</Col>
                                    <Col className="text-right font-weight-bolder">{prenotazione.quantita}</Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col>{homeData.testo132}</Col>
                                    <Col className="text-right font-weight-bolder">{prenotazione.data}</Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col>{homeData.testo133}</Col>
                                    <Col className="text-right font-weight-bolder">{prenotazione.ora}</Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col>{homeData.testo135}</Col>
                                    <Col className="text-right font-weight-bolder">€ {prenotazione.totale}</Col>
                                </Row>
                                <Row className="pb-2">
                                    <Col>{homeData.testo134}</Col>
                                    {(() => {
                                        switch (prenotazione.stato) {
                                            case "In sospeso":   return <Col className="text-right font-weight-bolder text-sospeso">{prenotazione.stato}</Col>;
                                            case "Rifiutato": return <Col className="text-right font-weight-bolder text-rifiutato">{prenotazione.stato}</Col>;
                                            case "Accettato":  return <Col className="text-right font-weight-bolder text-successo">{prenotazione.stato}</Col>;
                                            default:  return <></>;
                                        }
                                    })()}
                                </Row>
                                {prenotazione.menu &&
                                    <Elementi menu={prenotazione.menu} />
                                }
                            </div>
                        )}

                        {userData.data.utente.prenotazioni.length === 0 &&
                            <div className="p-4">{homeData.testo138}</div>
                        }
                    </Fade>
        </>
    )
}

export default Ordini;