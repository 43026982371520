import React from 'react';
import { Container, Fade, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { getHomeState } from '../../features/homepageSlice';

function AllServices(props) {
    const data = useSelector(getHomeState);
    //const [size, setSize] = useState(0)

    return (
        <Fade>
            <Row>
                <div className="btn-times">
                    <Link to={{pathname: props.location.query}} className="btn-times-icon d-block text-center">&times;</Link>
                </div>
            </Row>
            
            <Container className="text-center mt-5 px-4">
                <h5 className="pb-3">{data.testo170}</h5>
                <Row className="row-cols-2">
                {data.servizi && data.servizi.length > 0 && data.servizi.map((servizio, index) => {
                    return (

                    <div className='col pb-3 px-1  d-flex justify-content-center'>
                        <Link key={index} to={"/service/" + servizio.id} style={{backgroundImage: "url('"+servizio.copertina+"')", borderRadius: '10px',backgroundSize: 'cover'}}  className="box_categoria d-flex justify-content-center">
                            <div className="align-self-end text-center">
                                
                                {servizio.sconto !== "0" &&
                                    <div className="circle-text" style={{ fontSize: 'unset', position: 'absolute', right: '5%', top: '5%', width: '2rem', height: '2rem', background: '#5a6570'}}>%</div>
                                }
                                <img src={servizio.icona} width="40" height="40" alt="" />
                                <h4 className="pb-2 card-text text-center text-white">
                                    {servizio.nome}
                                </h4>
                                
                            </div>
                        </Link>
                    </div>
                    )
                })}
                </Row>
            </Container>
        </Fade>
    )
}
export default AllServices