function openNav() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    document.getElementById("mySidenav").style.width = "75%";
    document.getElementById("mySidenav").style.opacity = "1";
    //document.getElementById("main").style.marginLeft = "75%";
    document.getElementById("overlay").style.width = "100%";
    document.getElementById("overlay").style.marginLeft = "75%";
    //document.getElementById("mark").style.position = "fixed";
    //document.getElementById("img-logo").style.width = "180px"
}

export default openNav