import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getCheckin = createAsyncThunk("checkin", async (endpoint) => {
    try {
        const responce = await fetch(endpoint);
        if (!responce.ok) {
            throw Error(responce.statusText);
        }
        const json = await responce.json();
        return json;
    } catch (e) {
        throw Error(e);
    }
})


export const checkinSlice = createSlice({
    name: 'checkin',
    initialState: {
        loading: false,
        error: "",
        data: [],
    },
    reducers: {
    },
    extraReducers: {
        [getCheckin.pending]: state => { state.loading = true },
        [getCheckin.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.data = [];
        },
        [getCheckin.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.data = action.payload;
        }
    }
});

export default checkinSlice.reducer;

export const getCheckinState = state => state.checkin;

