import React, { useState } from 'react';
import { Form, Button, Container, Fade, Row, Col, /*Modal, ModalHeader, ModalBody, ModalFooter*/ } from 'reactstrap'
import Switch from 'react-ios-switch'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { getBootState } from '../../features/bootstrapSlice'
import { getLogin, getLoginState } from '../../features/loginSlice';
import { getHomeState } from '../../features/homepageSlice';
import { getUser } from '../../features/userSlice'
import setCookie from '../../functions/setCookie'
import getCookie from '../../functions/getCookie'
import { Redirect } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import Eye from './icons/eye-off.svg'
import FacebookLogin from 'react-facebook-login';
//import { FacebookLogin } from 'react-facebook-login-component';
import GoogleLogin from 'react-google-login';

function Login(props) {

    //modal recupero credenziali
    //const [modal, setModal] = useState(false);
    //const toggle = () => setModal(!modal);

    const [isCheckedPrivacy, setIsCheckedPrivacy] = useState(false);
    const [isCheckedMarketing, setIsCheckedMarketing] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [sesso, setSesso] = useState('');
    const [dataDiNascita, setDataDiNascita] = useState('');
    const [nazionalita, setNazionalita] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [motivo, setMotivo] = useState('');
    const [overlay, setOverlay] = useState(true)
    const bootData = useSelector(getBootState);
    const loginData = useSelector(getLoginState);
    const homeData = useSelector(getHomeState);

    var master = bootData.master;
    
    const dispatch = useDispatch();

    const recovery = async () => {
        let response = await fetch('/recovery_act.php?email='+email, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            }
        })
        const json = await response.json()
        if(json === "ok"){
            toast.success("Email di recupero inviata correttamente.");
        }else {
            toast.success("L'email non corrisponde a nessuna account");
        }
    }

    const submitForm = async (e) => {
        e.preventDefault()
        if (validator.isEmail(email) && !validator.isEmpty(nome) && !validator.isEmpty(cognome) && !validator.isEmpty(sesso) && !validator.isEmpty(dataDiNascita) && !validator.isEmpty(nazionalita) && !validator.isEmpty(checkIn) && !validator.isEmpty(checkOut) && isCheckedPrivacy === true) {
            //toast.success("Logged successfully!")
            //setto la nazionalità in base alla registrazione
            var lingua = 'EN'
            switch(nazionalita){
                case 'IT':
                    lingua = 'IT'
                    break
                case 'DE':
                    lingua = 'DE'
                    break
                case 'FR':
                    lingua = 'FR'
                    break
            }

            let response = fetch('/parser.php?lingua='+lingua, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    mode: 'cors'
                },
                body: ""
            })

            let data = {
                data: {
                    master: bootData.master,
                    nome: nome,
                    cognome: cognome,
                    telefono: telefono,
                    email: email,
                    country: nazionalita,
                    checkin: checkIn,
                    checkout: checkOut,
                    sesso: sesso,
                    datanascita: dataDiNascita,
                    marketing: isCheckedMarketing,
                    social_type: 'email',
                    motivo: motivo
                }
            }
            var login = dispatch(getLogin(data))
            console.log(login);            
        } else {
            let error = ''
            if (isCheckedPrivacy === false)
                error = error + "privacy policy, "
            if (validator.isEmpty(motivo))
                error = error + "motivo del soggiorno, "
            if (validator.isEmpty(sesso))
                error = error + "sesso,"
            if (!validator.isEmail(email))
                error = error + "email incorretta"

            toast.error(`Compila i seguenti campi: ${error}`)
        }
    }

    //check login data
    if(!loginData.loading) {
        //if(document.cookie.match('connesso')){
            if(loginData.data.logged){
                toast.success("Accesso effettuato!")
                dispatch(getUser({ 'userkey': `${getCookie('userkey')}`, 'master': `${getCookie('master')}`}));
                setCookie('connesso', true, 7)
                props.history.push("/") /*ricarica la home*/
            }else {
                console.log('Errore')
            }
        //}
    }

    //facebook login
    const [social, setSocial] = useState(false)
    const responseFacebook = (response) => {
        if(response.error){
            //console.log(response.error)
        }else {
            setNome(response.first_name)
            setCognome(response.last_name)
            setEmail(response.email)
            //document.getElementById(response.gender).checked = true;
            //setSesso(response.gender)
            //let birthday = response.birthday.split('/')
            //setDataDiNascita(birthday[2]+'-'+birthday[0]+'-'+birthday[1])
            setSocial(true)
        }
    }

    //facebook login
    //const [google, setGoogle] = useState(false)
    const responseGoogle = (response) => {
        if(response.error){
            //console.log(response)
        }else {
            setNome(response.profileObj.givenName)
            setCognome(response.profileObj.familyName)
            setEmail(response.profileObj.email)
            setSocial(true)
        }
    }

    return (
        <>
            { document.cookie.match('connesso')
                ?
                <Redirect to="/" />
                :
                <Fade>
                    {overlay && !props.location.hasOwnProperty('query') ?
                        <>
                            <div id="underlay" className="overlay-background" onClick={() => props.history.push("/")}></div>
                            <div className="overlay text-center" id="summary" style={{ zIndex: "2000" }}>
                                <Row className="py-2 mb-5 border-bottom">
                                    <Col></Col>
                                    <Col style={{"fonSize": "21px"}}>Ops!</Col>
                                    <Col className="text-right"><span onClick={() => props.history.push("/")} className="m-0 mr-3" style={{ fontSize: "21px", color: "#d4d4d5", top: "0", backgroundColor: "transparent" }}>&times;</span></Col>
                                </Row>
                                <img src={Eye} alt=""/>   
                                <h1 style={{"fontSize": "5vh"}} className="mx-5 mt-2 text-center font-weight-normal">{homeData.testo108}</h1>
                                <span className="d-block text-center m-3">{homeData.testo109}</span>
                                <Button onClick={() => setOverlay(false)} className="btn-first d-block mx-auto font-weight-bold mt-5" style={{width: "50%"}}>Login</Button>
                                <Button onClick={() => window.history.back()} className="btn-light d-block mx-auto font-weight-bold mt-3" style={{width: "50%"}}>{homeData.testo20}</Button>
                            </div>
                        </>
                        :
                        <>
                            <ToastContainer className="text-wrap" />
                            <div className="bg-light">
                                <span className="pt-3 d-block text-center" style={{ fontSize: "21px" }}>Sign In</span>
                                <Container className="mt-3">

                                    {!social &&
                                        <>
                                            {/* <FacebookLogin
                                            disableMobileRedirect={true}
                                            cookie={true}
                                            xfbml={true}
                                            autoLoad={false}
                                            appId="182164446926821"
                                            fields="first_name,last_name,birthday,gender,email,picture"
                                            callback={responseFacebook} 
                                            cssClass="my-2 btn-facebook w-100 btn btn-secondary"/> */}

                                            <FacebookLogin
                                                disableMobileRedirect={true}
                                                cookie={true}
                                                xfbml={true}
                                                autoLoad={false}
                                                appId="2621713201475799"
                                                fields="first_name,last_name,email,picture"
                                                callback={responseFacebook} 
                                                cssClass="my-2 btn-facebook w-100 btn btn-secondary"/>

                                            <GoogleLogin
                                            clientId="44085992358-j19c4sdb21uq60gh18rtltf7fj9uhsoi.apps.googleusercontent.com"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            render={renderProps => (
                                                <Button onClick={renderProps.onClick} className="my-2 btn-google w-100" disabled={renderProps.disabled}>Sign In with Google</Button>
                                            )}
                                            />

                                            <Row className="mt-3">
                                                <Col>
                                                    <hr />
                                                </Col>
                                                <Col>
                                                    <span className="text-nowrap">{homeData.testo127}</span>
                                                </Col>
                                                <Col>
                                                    <hr />
                                                </Col>
                                            </Row>
                                        </>
                                    }

                                    <Form onSubmit={submitForm}>
                                        {social &&
                                            <>
                                                <span className="text-center d-block">{homeData.testo121}</span>
                                            </>
                                        }
                                        <input onChange={(e) => setNome(e.target.value)} value={nome} name="nome" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo98} required />
                                        <input onChange={(e) => setCognome(e.target.value)} value={cognome} name="cognome " className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo99} required />
                                        <input onChange={(e) => setEmail(e.target.value)} value={email} name="email" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="Email" required />
                                            
                                        <input onChange={(e) => setNazionalita(e.target.value)} list="nazionalita" className="input-service my-2" placeholder={homeData.testo123} required/>
                                        <datalist id="nazionalita">
                                            {bootData.nazioni && bootData.nazioni.map((nazione) => 
                                                <option value={nazione.id}>{nazione.nome}</option>
                                            )}
                                        </datalist>

                                        {/* <select onChange={(e) => setNazionalita(e.target.value)} value={nazionalita} name="country" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="Nazionalità" required >
                                            {bootData.nazioni && bootData.nazioni.map((nazione) => 
                                                <option value={nazione.id}>{nazione.nome}</option>
                                            )}
                                        </select> */}

                                        <Row className="mt-2">
                                            <Col>
                                                <span className="ml-2" style={{ fontSize: "larger", color: "#d4d4d5" }}>{homeData.testo100}</span>
                                            </Col>
                                            <Col>
                                                <input type="radio" name="sesso" value="male" id="male" className="w-100" onClick={() => setSesso('m')} required/>
                                                <label htmlFor="male">{homeData.testo103}</label>
                                            </Col>
                                            <Col>
                                                <input type="radio" name="sesso" value="female" id="female" className="w-100" onClick={() => setSesso('f')} required/>
                                                <label htmlFor="female">{homeData.testo102}</label>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col style={{ borderRight: "1px solid #f5f5f5" }} >
                                                <span className="ml-2 text-nowrap" style={{ fontSize: "larger", color: "#d4d4d5" }}>{homeData.testo101}</span>
                                            </Col>
                                            <Col className="col-6">
                                                <input id="compleanno" type="date" className="input-service" onChange={(e) => setDataDiNascita(e.target.value)} value={dataDiNascita} style={{ borderColor: "#f5f5f5" }} placeholder="DD/MM/YY" required />
                                            </Col>
                                        </Row>
                                        <input onChange={(e) => setTelefono(e.target.value)} name="telefono" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder={homeData.testo57} />

                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <span>Check-in</span>
                                            </Col>
                                            <Col>
                                                <input type="date" className="input-service float-right" placeholder="30 Oct" onChange={(e) => setCheckIn(e.target.value)} required />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="d-flex align-items-center">
                                                <span>Check-out</span>
                                            </Col>
                                            <Col>
                                                <input type="date" className="input-service float-right" placeholder="2 Nov" onChange={(e) => setCheckOut(e.target.value)} required />
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col className="d-flex align-items-center">
                                                <Link to={'/'+master+'/privacy.html'}>Privacy Policy</Link>
                                            </Col>
                                            <Col>
                                                <Switch
                                                    checked={isCheckedPrivacy}
                                                    onChange={() => { setIsCheckedPrivacy(!isCheckedPrivacy) }}
                                                    className="d-flex float-right"
                                                    style={{ transform: "scale(0.8)" }}
                                                    offColor="#37363b"
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="d-flex align-items-center">
                                                <Link to={'/'+master+'/terms.html'}>Marketing Policy</Link>
                                            </Col>
                                            <Col>
                                                <Switch
                                                    checked={isCheckedMarketing}
                                                    onChange={() => { setIsCheckedMarketing(!isCheckedMarketing) }}
                                                    className="d-flex float-right"
                                                    style={{ transform: "scale(0.8)" }}
                                                    offColor="#37363b"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                <hr />
                                            </Col>
                                            <Col>
                                                <span className="text-nowrap">{homeData.testo126}</span>
                                            </Col>
                                            <Col>
                                                <hr />
                                            </Col>
                                        </Row>
                                        <Row className="mt-1">
                                            <Col>
                                                <input type="radio" name="stay" value="vacation" id="vacation" className="w-100" onClick={() => setMotivo('V')} />
                                                <label htmlFor="vacation">{homeData.testo104}</label>
                                            </Col>
                                            <Col>
                                                <input type="radio" name="stay" value="work" id="work" className="w-100" onClick={() => setMotivo('L')} />
                                                <label htmlFor="work">{homeData.testo105}</label>
                                            </Col>
                                            <Col>
                                                <input type="radio" name="stay" value="other" id="other" className="w-100" onClick={() => setMotivo('A')} />
                                                <label htmlFor="other">{homeData.testo106}</label>
                                            </Col>
                                        </Row>
                                        
                                        <Button type="submit" className="my-3 btn-first w-100 font-weight-bold">SIGN IN</Button>
                                        {/* RECUPERO CREDENZIALI  
                                        <hr/>
                                        <Button className="my-3 w-100 btn-light" onClick={toggle}>Recupera credenziali</Button>
                                        <Modal isOpen={modal} centered toggle={toggle}>
                                            <ModalHeader toggle={toggle}>Recupera i tuoi dati</ModalHeader>
                                            <ModalBody>
                                                <p>Inserisci la tua email</p>
                                                <input onChange={(e) => setEmail(e.target.value)} name="email" className="input-service my-2" style={{ borderColor: "#f5f5f5" }} placeholder="esempio@esempio.it" required />
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="primary" onClick={recovery}>Invia email</Button>{' '}
                                                <Button color="secondary" onClick={toggle}>Annulla</Button>
                                            </ModalFooter>
                                        </Modal> */}
                                    </Form>
                                </Container>
                            </div>
                        </>}
                </Fade>}
        </>
    )
}


export default Login
