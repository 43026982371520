import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Card, Container, Spinner} from 'reactstrap';
import { getQuestionario, getQuestState } from '../../features/questSlice';
import Domanda from './Domanda';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';



function Questionario(props){

    const [lang, setLang] = useState("");

    const questparams = props.match.params.decode;

    let params = atob(questparams);

    let history = useHistory();

    const dispatch = useDispatch();
    useEffect(() =>{
        setLang(query.q_lingua);
        dispatch(getQuestionario('/get-quest/'+questparams));
    }, []); 
    const dataQuest = useSelector(getQuestState);


    function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    let query = parseQuery(params); 

    /*function parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }*/

    //let decode = atob(questparams);
    
    //let params = parseQuery(decode);

    /*const sendQuest = () => {
        const data = {data: 
            { utente: dataQuest.data.utid, 
              questionario: parseInt(dataQuest.data.quid), 
              start: dataQuest.data.start, 
              invio: new Date().getTime(),
             }}
             
        data.data = Object.assign(data.data, props.answers);
        console.log(data);
        dispatch(sendQuestionario(data));
        history.push('/')
    }*/

    const changeLang = e =>{
        params = params.replace(lang, ""+e.target.value);
        setLang(e.target.value);
        let querystr = btoa(params);
        
        dispatch(getQuestionario('/get-quest/'+querystr));
        history.push("/anteprimaquestionario/"+querystr);
    }

    if(dataQuest.loading){
        return (
            <div className="loading">
                <Spinner className="spinner" color="danger" />
            </div> 
        );
    }else if(!dataQuest.loading && dataQuest.error === ''){
        return(
            <Container style={{marginTop: "2%"}}>
                <div style={{color: "#475F7B"}} className="row m-1">
                    <option value="IT" style={{fontWeight: lang ==='IT' ? "bold" : "", cursor: "pointer"}} onClick={changeLang} >ITA</option>
                    {dataQuest.data.lingue.map((lingua) => {
                        return <option value={lingua} style={{fontWeight: lang === lingua ? "bold" : "", cursor: "pointer"}} onClick={changeLang} >{lingua}</option>
                    })}
                </div>
                <Card style={{marginBottom: "4%"}}>
                <form className="questionario">
                <div className="questionario" style={{padding: "5%"}}>
                    <div className="img-fluid" style={{float: "right", marginRight:"32px", padding:"2%"}}>
                        <img style={{ maxHeight:"134px", maxWidth:"134px"}} src={dataQuest.data.logo} alt=""/>
                    </div>
                    <h4 style={{fontWeight: "bold", fontSize: "18px", color: "#475F7B"}}>{dataQuest.data.titolo}</h4>
                    <p style={{fontSize: "18px", color: "#475F7B", paddingTop:"8%", paddingBottom: "1%"}}>
                        {Parser(dataQuest.data.descrizione)}
                    </p>
                    <hr style={{border: "1px solid #E3E7EA"}}></hr>
                        {dataQuest.data.domande.map((domanda, index) =>{
                        /*return fetchQuestions(domanda, index, updateField, updateTextState);*/
                            return <Domanda domanda={domanda} index={index} />
                        })}
                </div>
                </form>
              </Card>
            </Container>
        );

    }else if(!dataQuest.error !==''){
        return "Error";
    }
}

function mapStateToProps(state){
    return { answers: state.quest.answers }
}

export default connect(mapStateToProps)(Questionario);