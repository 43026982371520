import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Col, Dropdown, DropdownToggle, DropdownMenu,  DropdownItem } from 'reactstrap';
import User from '../Mobile/icons/User_side.svg'
import Pin from '../Mobile/icons/Pin map.svg'
import Phone from '../Mobile/icons/phone2.svg'
import Chat from '../Mobile/icons/chat2.svg'
import Web from '../Mobile/icons/Web.svg'
import Favorite from '../Mobile/icons/Like cuore.svg'
import getCookie from '../../functions/getCookie'
import { getBootState } from '../../features/bootstrapSlice';
import { getHomeState } from '../../features/homepageSlice';
import Whatsapp from '../Mobile/icons/whatsapp.svg';
import allserviceicon from '../Mobile/icons/allservice.svg';

function Navbar(){

    const bootdata = useSelector(getBootState);
    const data = useSelector(getHomeState);
    const [servicePanel, setServicePanel] = useState(false)

    const openServicePanel = () => {
        document.getElementById("servicePanel").style.width = "100%";
        setServicePanel(false);
    }

    //gestione lingue
    const [lingua, setLingua] = useState(getCookie('lingua') ? getCookie('lingua') : 'IT');

    const setLinguaF = async (lingua) => {
        
        let response = await fetch('/parser.php?lingua='+lingua, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            },
            body: ""
        })

        window.location.reload(false)
        setLingua(lingua)
    }

      //dropdown lingue
      const [dropdownOpen, setdropdownOpen] = useState(false);
        const toggleDropdown = () => {
          setdropdownOpen(!dropdownOpen);
        }

         //dropdown lingue
      const [dropdownOpenEmergency, setdropdownOpenEmergency] = useState(false);
      const toggleDropdownEmergency = () => {
        setdropdownOpenEmergency(!dropdownOpenEmergency);
      }
       // const bootdata = useSelector(getBootState);

    return(

            <div className="navbar sticky-top p-0" style={{backgroundColor: "white"}}>
                <div className="container-fluid first-nav">
                    <div className="d-flex align-items-center">
                        <Col>
                            <Dropdown nav={true} className="label-lang" isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle tag="span" caret className='pointer'>
                                    <>{lingua}</>
                                </DropdownToggle >
                                <DropdownMenu style={{minWidth:"auto"}}>
                                        {bootdata.lingue && bootdata.lingue.map((lingua, index) =>
                                            <DropdownItem key={index} tag="span" className="p-3 label-lang pointer" onClick={() => setLinguaF(lingua.id)}>
                                                <img src={"/dashboard/images/"+lingua.flag} className="mr-2" width="20" alt=""/>
                                                <span>{lingua.id}</span>
                                            </DropdownItem>
                                        )}  
                                </DropdownMenu>
                            </Dropdown >
                        </Col>
                        {bootdata.posto_master.latitudine !== "0" && bootdata.posto_master.longitudine !== "0" &&
                        <Col>
                            <a href={"https://maps.google.com/maps?daddr="+bootdata.posto_master.latitudine+","+bootdata.posto_master.longitudine+"&amp;ll="} target="_blank" rel="noreferrer" className="p-0"><img src={Pin} className="icon-small" alt=""/></a>
                        </Col>
                        }
                        {bootdata.posto_master.telefono !== "" &&
                        <Col>
                            <a href={"tel:"+bootdata.posto_master.telefono} className="p-0"><img src={Phone} className="icon-small" alt=""/></a>
                        </Col>
                        }
                        {bootdata.posto_master.whatsapp !== "" &&
                        <Col>
                            <a href={bootdata.posto_master.whatsapp} target="_blank" className="p-0"><img src={Whatsapp} className="icon-small" alt=""/></a>
                        </Col>
                        }
                        {bootdata.posto_master.email !== "" &&
                        <Col>
                            <a href={"mailto:"+bootdata.posto_master.email} className="p-0"><img src={Chat} className="icon-small" alt=""/></a>
                        </Col>
                        }
                        {bootdata.posto_master.sito !== "" &&
                        <Col>
                            <a href={bootdata.posto_master.sito} target="_blank" rel="noreferrer" className="p-0"><img src={Web} className="icon-small" alt=""/></a>
                        </Col>
                        }
                    </div>
                    <div className="d-flex">
                        <div className="col">
                            <div className="row align-items-center">
                            {document.cookie.match('connesso') 
                            ?
                                <>
                                    <div className="mr-4 first-nav-right">
                                    <NavLink to="/favorite" activeClassName="nav-item-active">
                                        <span className="side-text nav-right-text">{data.testo95}</span>
                                        <img src={Favorite} className="icon-p" alt=""/>
                                    </NavLink>
                                    </div>
                                    <div className="first-nav-right">
                                    <NavLink to="/profile" activeClassName="nav-item-active">
                                        <span className="side-text nav-right-text">{data.testo94}</span>
                                        <img src={User} className="icon-p" alt=""/>
                                    </NavLink>
                                    </div>
                                </>
                            :
                                <div style={{height: '50px'}}></div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="navbar navbar-light w-100 second-nav">

                    <Link className="navbar-brand" to={"/"}>
                        <img src={bootdata.logomaster} style={{maxHeight: '40px', maxWidth: '250px'}} alt=""/>
                    </Link>

                    <div className='row d-flex'>   
                        <NavLink exact activeClassName="nav-item-active" className="align-items-center d-flex mx-4" style={{height: '70px'}} to="/">
                            <span>{data.lotesto17}</span><span className="sr-only">(current)</span>
                        </NavLink>

                        <NavLink exact activeClassName="nav-item-active" className="align-items-center d-flex mx-4" style={{height: '70px'}} to="/info">
                            {bootdata.master === '1551'
                            ?
                                "Info Discover Turin"
                            :
                                "Hotel Info"
                            }
                        </NavLink>
                        
                        <Dropdown className="pointer nav-item d-flex align-items-center nav-link" isOpen={dropdownOpenEmergency} toggle={toggleDropdownEmergency}>
                            <DropdownToggle tag="span">
                            {data.mltesto1}
                            </DropdownToggle >
                            <DropdownMenu style={{minWidth:"auto"}}>
                                <DropdownItem key="1" tag="span" className="p-3 label-lang" onClick={()=> window.open('tel:112')}>
                                    <img src={Phone} className="mr-2" width="20" alt=""/>
                                        <span>Numero Unico Europeo 112</span>
                                </DropdownItem>
                                {/* <DropdownItem key="1" tag="span" className="p-3 label-lang" onClick={()=> window.open('tel:113')}>
                                    <img src={Phone} className="mr-2" width="20" alt=""/>
                                        <span>{data.mltesto14} 113</span>
                                </DropdownItem>
                                <DropdownItem key="2" tag="span" className="p-3 label-lang" onClick={()=> window.open('tel:115')}>
                                    <img src={Phone} className="mr-2" width="20" alt=""/>
                                        <span>{data.mltesto15} 115</span>
                                </DropdownItem>
                                <DropdownItem key="3" tag="span" className="p-3 label-lang" onClick={()=> window.open('tel:118')}>
                                    <img src={Phone} className="mr-2" width="20" alt=""/>
                                        <span>{data.mltesto2} 118</span>
                                </DropdownItem> */}
                            </DropdownMenu>
                        </Dropdown >

                        {/* <a onClick={openServicePanel} className='col ml-3 px-4 mr-4 btn btn-custom d-flex justify-content-center align-self-center w-auto' style={{height: '50px'}}>
                            <div className='mr-3 text-white align-self-center' style={{fontWeight: '400', fontSize: '16px', lineHeight: '24px'}}>Servizi</div> 
                            <img className='align-self-center' width={25} src={allserviceicon} />
                        </a> */}

                    </div>

                </nav>
            </div>
        
    )
}


export default Navbar;