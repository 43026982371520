import { configureStore } from '@reduxjs/toolkit';
import bootstrapSlice from '../features/bootstrapSlice';
import homepageReducer from '../features/homepageSlice';
import serviceSlice from '../features/serviceSlice'
import placeSlice from '../features/placeSlice'
import loginSlice from '../features/loginSlice';
import userSlice from '../features/userSlice';
import searchSlice from '../features/searchSlice';
import questSlice from '../features/questSlice';
import itinerarySlice from '../features/itinerarySlice';
import geolocationSlice from '../features/geolocationSlice';
import recentSlice from '../features/recentSlice';
import moduloRep from '../features/moduloRepSlice';
import checkinSlice from '../features/checkinSlice';

export default configureStore({
  reducer: {
    bootstrap: bootstrapSlice,
    homepage: homepageReducer,
    service: serviceSlice,
    place: placeSlice,
    login: loginSlice,
    user: userSlice,
    quest: questSlice,
    itinerary: itinerarySlice,
    geolocation: geolocationSlice,
    search: searchSlice,
    recent: recentSlice,
    repSlice: moduloRep,
    checkin: checkinSlice
  }
});
