function closeNav() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    document.getElementById("main").style.overflow = "auto";
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.opacity = "0";
    document.getElementById("main").style.marginLeft = "0";
    document.getElementById("overlay").style.width = "0%";
    //document.getElementById("mark").style.position = "unset";
    //document.getElementById("img-logo").style.width = "0"
}

export default closeNav