import { Link } from 'react-router-dom'

function ListaServizi({homeData}) {
return(
    <div className="testimonial-group mb-3">
        <div className="row py-2">
            {homeData.servizi && homeData.servizi.length > 0 && homeData.servizi.map((servizio, index) => servizio.evidenza === "1" &&
                <Link key={index} to={"/service/" + servizio.id} style={{backgroundColor: 'white', borderRadius: '10px'}}  className="box_categoria_v1 col-8 py-3 px-2 p-0 mb-0 mr-2">
                    <div className="text-center">
                        <img src={servizio.icona} width="40" height="40" alt="" />
                        <h4 style={{marginTop: "0.8rem",fontSize: "calc(0.35em + 1vmin)"}} className="card-text text-center text-truncate">{servizio.nome}
                        {servizio.sconto !== "0" &&
                            <div className="circle-text" style={{ fontSize: 'unset', position: 'absolute', right: '5%', top: '5%', width: '2rem', height: '2rem', background: '#5a6570'}}>%</div>
                        }
                        </h4>
                    </div>
                </Link>
            )}
        </div>    
    </div>

    )
}

export default ListaServizi