import React, { useState } from 'react';
import '../../functions/openNav'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Fade, Row, Spinner, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { emptyCart, getServiceState, getCart, removeItem, updateDataDal, updateDataAl } from '../../features/serviceSlice';
import getCookie from '../../functions/getCookie';
import { getHomeState } from '../../features/homepageSlice';
import Edit from './icons/edit.svg'
import Close from './icons/close.svg'
import { useHistory } from 'react-router-dom'
import { getUserState } from '../../features/userSlice'
import Trash from '../Mobile/icons/delete.png'


function Cart() {

    const history = useHistory();

    const homeData = useSelector(getHomeState);

    var camera = "";
    var nome = "";
    var cognome = "";
    var email = "";
    var oreDalle = "";
    var oreAlle = "";
    var dataDal = "";
    var dataAl = "";
    
    const [note, setNote] = useState('')
    const [overlay, setOverlay] = useState(false)

    const [postLoading, setPostLoading] = useState(false)

    //dati utente loggato
    const userData = useSelector(getUserState);

    const dispatch = useDispatch();

    function convertDate(date){
        if(date && date.length >= 9)
            return date.split('-')[2]+"/"+date.split('-')[1]+"/"+date.split('-')[0]
        else
            return "..."
    }

    //svuota il carrello e chiude l'ordine
    const emptyOrder = async () => {
        dispatch(emptyCart())
        setOverlay(false)
    }

    const submitOrder = async () => {
        setPostLoading(true);
        camera = document.getElementById('camera').value;
        nome = document.getElementById('nome').value;
        cognome = document.getElementById('cognome').value;
        email = document.getElementById('email').value;
        oreDalle = document.getElementById('oreDalle').value;
        oreAlle = document.getElementById('oreAlle').value;
        dataDal = document.getElementById('dataDal').value;
        dataAl = document.getElementById('dataAl').value;

        if(oreDalle !== "" && dataDal !== "" && email !== ""){
            let items = []

            cart.itemsAdded.map((item, index) =>
                items = [
                    ...items,
                    {
                        seid: ("seid" in item ? item.seid : 0),
                        id: item.id,
                        quantita: item.quantity,
                        giorni: convertDate(item.giorniDal) + " - " + convertDate(item.giorniAl),
                        ore: (item.oreDalle ? item.oreDalle : "...") + " - " + (item.oreAlle ? item.oreAlle : "...") ,
                    }
                ]
            )

            let obj = {
                servizio: true,
                utente: getCookie('userkey'),
                note: note,
                camera: camera,
                nome: nome,
                cognome: cognome,
                email: email,
                giorni: convertDate(dataDal) + " - " + convertDate(dataAl),
                ore: (oreDalle ? oreDalle : "...") + " - " + (oreAlle ? oreAlle : "...") ,
                cart: {
                    items: items
                }
            };

            let response = await fetch('/prenota_act.php', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
            setPostLoading(false);
            dispatch(emptyCart())
            dispatch(()=>setOverlay(false))
            history.push("/thankyou")
        }else {
            //messaggio di errore
            setPostLoading(false);
            var elm;
            if(email === '') {
                elm = document.getElementById('email');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("email").scrollIntoView();
            }else {
                elm = document.getElementById('email');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(oreDalle === '') {
                elm = document.getElementById('oreDalle');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("oreDalle").scrollIntoView();
            }else {
                elm = document.getElementById('oreDalle');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
            if(dataDal === '') {
                elm = document.getElementById('dataDal');
                elm.classList.add("border");
                elm.classList.add("border-danger");
                document.getElementById("dataDal").scrollIntoView();
            }else {
                elm = document.getElementById('dataDal');
                elm.classList.remove("border");
                elm.classList.remove("border-danger");
            }
        }
    }

    const servizio = useSelector(getServiceState);
    const cart = useSelector(getCart);

    var supplemento = cart.itemsAdded.some(function (val) {
        return val.supplemento > 0 || servizio.supplemento > 0;
    });
    
    return (
        <Fade>

            {cart.total >= 0 /* && !overlay */ && cart.itemsAdded.length > 0 &&
                <nav className="navbar fixed-bottom navbar-light" style={{zIndex:1,backgroundColor:'white',boxShadow: '0 -13px 31px -15px rgba(53, 53, 53, 0.63)'}}>
                    <Button onClick={() => setOverlay(true)} className="d-block my-2 btn-first font-weight-bold" style={{ width: "100%" }}>{homeData.testo161} <span className="float-right">€ {cart.total}</span></Button>
                </nav>
            }

            <div id="servizi-overlay">
                {servizio &&
                    <>
                        {/* STAMPA IL RIEPILOGO DELL'ORDINE */}
                            <Modal isOpen={overlay} centered={true} scrollable={true}>
                                <Row className='p-3'>
                                    <Col className='col-auto'></Col>
                                    <Col className='text-center'>
                                        <h4 className="font-weight-normal">{homeData.testo157}</h4>
                                    </Col>
                                    <Col className='col-auto'>
                                        <img onClick={() => setOverlay(false)} src={Close}/>
                                    </Col>
                                </Row>
                            <ModalBody>
                                <div>
                                    {supplemento &&
                                        <>
                                        <hr></hr>
                                        <h6 className="m-3 mt-3 text-center font-weight-normal">{homeData.testo158}</h6>
                                        <hr></hr>
                                        </>
                                    }
                                    <Container>
                                        {cart.itemsAdded.map((item, index) =>
                                            <Row key={index} className="m-0 my-2 border-bottom">
                                                <Col className="p-0">
                                                    <span className="font-weight-bold font-size-large">{item.quantity + "x " + item.nome}</span>
                                                    {/* <Row className="pb-3 mt-2 m-0">
                                                        <Col className="col-auto p-2 mr-2" style={{ borderRadius: '2px', border: 'solid 1px #d4d4d5', color: '#d4d4d5' }}>
                                                            {item.giorniDal} - {item.giorniAl}
                                                            <img src={Calendar} width="24" className="ml-1" alt="" />
                                                        </Col>
                                                        <Col className="col-auto p-2" style={{ borderRadius: '2px', border: 'solid 1px #d4d4d5', color: '#d4d4d5' }}>
                                                            {item.oreDalle} - {item.oreAlle}
                                                            <img src={Clock} width="24" className="ml-1" alt="" />
                                                        </Col>
                                                    </Row> */}
                                                </Col>
                                                <Col className='col-auto'>                                                   
                                                {item.sconto > 0
                                                    ?
                                                        <span className="font-weight-bold font-size-large primary-color float-right">{"€ " + parseFloat(item.sconto).toFixed(2) * item.quantity}</span>
                                                    :
                                                        <span className="font-weight-bold font-size-large primary-color float-right">{"€ " + parseFloat(item.prezzo).toFixed(2) * item.quantity}</span>
                                                    }
                                                </Col>
                                                <Col className='col-auto pr-0 pointer'><img src={Trash} height={20} onClick={() => dispatch(removeItem(item))}/></Col>
                                            </Row>
                                        )}
                                        <hr className="mt-3" />
                                        <div className="pb-3">
                                            <span className="font-weight-bold font-size-large">{homeData.testo135}</span>
                                            <span className="font-weight-bold font-size-large primary-color float-right">{"€ " + cart.total}</span>
                                        </div>
                                        <img src={Edit} width="24" alt="" />
                                        <textarea className="input-service mt-3" placeholder={homeData.testo168} style={{ height: "100px" }} onChange={(e) => setNote(e.target.value)}></textarea>
                                        <Row>
                                            <Col className="col-4">
                                                {homeData.testo128}*
                                            </Col>
                                        </Row>
                                        <Row className="pb-3">
                                            <Col className="col-5 pr-0">
                                                <input id='oreDalle' type="time" className="input-service" placeholder="Dalle" required/>
                                            </Col>
                                            <Col className="col pt-2 text-center">-</Col>
                                            <Col className="col-5 pl-0">
                                                <input id='oreAlle' type="time" className="input-service" placeholder="alle" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-4">
                                                Data*
                                            </Col>
                                        </Row>
                                        <Row className="pb-3">
                                            <Col className="col-5 pr-0">
                                                <input id='dataDal' onChange={(e) => dispatch(updateDataDal({ data: e.target.value }))} value={cart.dataDal} type="date" className="input-service" placeholder="Dal" required/>
                                            </Col>
                                            <Col className="col pt-2 text-center">-</Col>
                                            <Col className="col-5 pl-0">
                                                <input id='dataAl' onChange={(e) => dispatch(updateDataAl({ data: e.target.value }))} value={cart.dataAl} min={cart.dataDal} type="date" className="input-service" placeholder="al"/>
                                            </Col>
                                        </Row>

                                        {!userData.loading && "utente" in userData.data
                                        ?
                                            <>
                                                <input id="camera" defaultValue={userData.data.utente.camera} type="text" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo162}/>
                                                <input id="nome" defaultValue={userData.data.utente.nome} className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo98} required />
                                                <input id="cognome" defaultValue={userData.data.utente.cognome} className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo99} required />
                                                <input id="email" defaultValue={userData.data.utente.email} className="input-service my-2" style={{ height: "auto" }} placeholder="Email" required />
                                            </>
                                        :
                                            <>
                                                <input id="camera" type="text" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo162}/>
                                                <input id="nome" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo98} required />
                                                <input id="cognome" className="input-service my-2" style={{ height: "auto" }} placeholder={homeData.testo99} required />
                                                <input id="email" className="input-service my-2" style={{ height: "auto" }} placeholder="Email" required />
                                            </>
                                        }

                                    </Container>
                                </div>
                            </ModalBody>
                            <ModalFooter className='d-flex align-items-between'>
                                <Button className="btn-light font-weight-bold" onClick={() => emptyOrder()}>{homeData.testo160}</Button>

                                {!postLoading
                                ?
                                    <Button className="btn-first font-weight-bold" onClick={() => submitOrder()}>{homeData.testo159}</Button>
                                :
                                    <Button type="submit" className="btn-first font-weight-bold">
                                        <Spinner style={{ width: '1rem', height: '1rem' }} />
                                    </Button>
                                }
                            </ModalFooter>
                        </Modal>
                        {/* FINE STAMPA RIEPILOGO ORDINE */}
                    </>
                }
            </div>
        </Fade>
    )

}

export default Cart