import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getLogin = createAsyncThunk("login", async(login_data) => {
    try {
        const response = await fetch("/login_act.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            },
            body: JSON.stringify(login_data)
        });
        const json = await response.json();
        return json;
    } catch (e) {
        throw Error(e);
    }
})


export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        loading: true,
        error: "",
        data: [],
    },
    reducers: {
        logout(state, action) {
            state.data = []
        }
    },
    extraReducers: {
        [getLogin.pending]: state => { state.loading = true },
        [getLogin.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            
        },
        [getLogin.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.data = action.payload;

        }
    }
});

export default loginSlice.reducer;

export const { logout } = loginSlice.actions

export const getLoginState = state => state.login;