import React, { useState } from 'react';
import {  Button, ButtonGroup, ButtonToolbar, Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setSubAnswers, setAnswers } from '../../features/questSlice';

function Sottodomanda(props){

    const dispatch = useDispatch();

    const [activeButtons, /*setActiveButtons*/] = useState({});

    const updateField = e =>{

    if(e.target.dataset.questiontype === "RV"){    
        if(activeButtons[e.target.name] !== ""){
            let elem = document.getElementsByName(e.target.name);

            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === activeButtons[e.target.name]){
                    elem[i].className = "btn-valutazione";
                }
            }

            
            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === e.target.id){
                    elem[i].className = "btn-valutazione active";
                }

            }
            activeButtons[e.target.name] = e.target.id;
        }else{
            let elem = document.getElementsByName(e.target.name);
            for(let i = 0; i < elem.length; i++){
                if(elem[i].id === e.target.id){
                    elem[i].className = "btn-valutazione active";
                }
            }
            activeButtons[e.target.name] = e.target.value;

        }
       
        let answer = {id: e.target.name, vote: e.target.id, qt: e.target.dataset.questiontype};

        dispatch(setAnswers({name: "voto"+e.target.name, vote: e.target.id}));
     
        dispatch(setSubAnswers(answer));
    }else{
        switch(e.target.dataset.questiontype){
            case'RO':
                dispatch(setAnswers({name: "opzione"+e.target.name, vote: e.target.id}));
            break;

            case 'RM':
                dispatch(setAnswers({name: "opzione"+e.target.name, vote: e.target.id}));
            break;

            default: break;
        }
    }
}

    const updateTextState = e => {
        dispatch(setAnswers({name: e.target.name, vote: e.target.value}));
    }

    switch(props.sottodomanda.tipologia){
        case 'RV':
            return(
              <div>
                    <div style={{marginLeft: "6px", borderLeft: "1px dashed #727E8C", marginTop: "3%", marginBottom: "3%"}}>
                    <p style={{color: "#727E8C", marginLeft: "30px"}}>
                        <b>{props.main}.{props.index}</b> {props.sottodomanda.testo}<br/><br/>
                        <ButtonToolbar style={{verticalAlign: "middle", display:"table"}}>
                        <span style={{display:"table-cell"}} className="text-center text-valutazione">Pessimo</span>
                        <ButtonGroup onClick={updateField} >
                                <Button name={props.sottodomanda.doid} type="button" id="1" data-questiontype="RV" className="btn-valutazione">1</Button>
                                <Button name={props.sottodomanda.doid} type="button" id="2" data-questiontype="RV" className="btn-valutazione">2</Button>
                                <Button name={props.sottodomanda.doid} type="button" id="3" data-questiontype="RV" className="btn-valutazione">3</Button>
                                <Button name={props.sottodomanda.doid} type="button" id="4" data-questiontype="RV" className="btn-valutazione">4</Button>
                                <Button name={props.sottodomanda.doid} type="button" id="5" data-questiontype="RV" className="btn-valutazione">5</Button>
                        </ButtonGroup>
                        <span style={{display:"table-cell"}} className="text-center text-valutazione">Eccellente</span>
                        </ButtonToolbar>
                    </p>
                    </div>
                </div>
                );
        case 'RL':
            return(
                <div style={{marginLeft: "6px", borderLeft: "1px dashed #727E8C", marginTop: "3%", marginBottom: "3%"}}>
                    <p style={{color: "#727E8C", marginLeft: "30px"}}>
                        <b>{props.main}.{props.index}</b> {props.sottodomanda.testo}
                    <FormGroup >
                        <Input name={"libero"+props.sottodomanda.doid} onChange={updateTextState} style={{minHeight: "90px", marginTop: "2%"}} type="textarea" id="exampleText" />
                    </FormGroup>
                    </p>
                </div>
            );

        case 'RO':
            return(
                <div style={{marginLeft: "6px", borderLeft: "1px dashed #727E8C", marginTop: "3%", marginBottom: "3%"}}>
                    <p style={{color: "#727E8C", marginLeft: "30px"}}>
                    <p style={{color: "#727E8C"}}>
                        <b>{props.main}.{props.index}</b> {props.sottodomanda.testo}
                    </p>
                <Form>
                {props.sottodomanda.opzioni.map((opzione) =>{
                    return(
                    <FormGroup style={{paddingRight: "3%", marginTop:"2%"}} check inline>
                        <Label style={{marginLeft: "25px", display: "flex"}} className="label checkbox checkbox-label" check>
                        <Input name={props.sottodomanda.doid} id={opzione.opid} onChange={updateField} data-questiontype="RO" type="radio"/><div>{opzione.optesto}</div>
                        <span className="checkmark"></span>
                        </Label>
                    </FormGroup>
                    );
                })}
                </Form>

                    </p>
                </div>

            );
        case 'RM':
            return(
            <div style={{marginLeft: "6px", borderLeft: "1px dashed #727E8C", marginTop: "3%", marginBottom: "3%"}}>
                <p style={{color: "#727E8C", marginLeft: "30px"}}>
                <p style={{color: "#727E8C"}}>
                    <b>{props.main}.{props.index}</b> {props.sottodomanda.testo}
                </p>
                <Form>
                <div className="col">
                {props.sottodomanda.opzioni.map((opzione)=>{
                    return(
                    <FormGroup  style={{paddingTop: "1%", paddingBottom: "1%"}}  check>
                        <Label  style={{ display: "flex", alignItems:"center"}} className="label checkbox checkbox-label" check>
                        <Input name={props.sottodomanda.doid} id={opzione.opid} data-questiontype="RM" onChange={updateField} type="radio" /><div>{opzione.optesto}</div>
                        <span  className="checkmark"></span>
                        </Label>
                    </FormGroup>
                    )
                })}
                </div>
                </Form>
                </p>
            </div>

            );
        default: return (<></>)
    }
}

export default Sottodomanda;