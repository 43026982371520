import React from 'react';
import { logout } from '../../features/userSlice'
import { logout as logoutLogin } from '../../features/loginSlice'
import setCookie from '../../functions/setCookie'
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom'

function Logout() {

    const dispatch = useDispatch();
    dispatch(logout()) //svuota lo state
    dispatch(logoutLogin()) //svuota lo state del login
    setCookie('connesso','',-100)
    setCookie('userkey','',-100)

    //unset sessioni server (connesso - userkey)
    let response = fetch('/parser.php?action=logout', {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            mode: 'cors'
        },
        body: ""
    })

    return (
        <>
            <Redirect to="/login" />
        </>
    )
}

export default Logout
