import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Spinner } from 'reactstrap';
import { getReputazione, getRepState, showMore, orderRews } from '../../features/moduloRepSlice';
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps"
import "../../index.css";

function ModuloReputazione(props){

    const master = props.match.params.master;

    //console.log(master);

    const dispatch = useDispatch();
    useEffect(() =>{
        dispatch(getReputazione("/get-certificato/"+master));
    }, []); 
    const dataReputazione = useSelector(getRepState);

    if(dataReputazione.loading){
        return(
            <div className="loading">
                <Spinner className="spinner" color="danger" />
            </div> 
        );
    } else if (!dataReputazione.loading && dataReputazione.error === ''){
        const data = dataReputazione.data;
        const rewiewsToShow =  dataReputazione.rewiews.slice(0, dataReputazione.numberRewsShown);
        const remainingRewiews = dataReputazione.rewiews.length - dataReputazione.numberRewsShown;

        //window.history.replaceState(null, dataReputazione.data.hotel.nome, "/moduloreputazione/"+dataReputazione.data.hotel.qrcode.replace(/\s/g, ''));

        const MapWithAMarker = withGoogleMap(props =>
            <GoogleMap
              defaultZoom={14}
              defaultCenter={{ lat: parseFloat(data.hotel.latitudine), lng: parseFloat(data.hotel.longitudine) }}
              options={{disableDefaultUI: true}}
            >
              <Marker
                position={{ lat: parseFloat(data.hotel.latitudine), lng: parseFloat(data.hotel.longitudine) }}
              />
            </GoogleMap>
          );
    return(
        <div>
            <div className="header-container">
            {/*<iframe className="fixedIframe" id="fixediframe" src="http://localhost/widget.php?master=243&lingua=it&posizione=sx#" />*/}
                <div className="fixedIframe">
                    <a className="text-link mt-auto mb-auto" href="#" id="box" data-toggle="popover" data-popover-content="#to-show">
                        <div className="row">
                            <img className="mt-4 mr-auto ml-auto" src="/dashboard/images/badge.svg" style={{height: "33px;"}}/>
                        </div>
                        <div className="text-center widgetBody" style={{height: "40% !important", marginTop: "20%"}}>
                            <div style={{fontSize:"18px"}}>
                                    {data.widget.setting[0] === "1" ? "\"Eccellente\"" : ""}
                            </div>
                            <div style={{fontSize: "25px"}}>
                                {data.widget.media_recensioni}%
                            </div>
                            <div className="ml-4 mr-4 text-18">
                                {data.widget.setting[1] === "1" ? data.widget.tot_recensioni+" recensioni " : ""}
                                {data.widget.setting[2] === "1" ?  "su "+data.widget.num_portali+" portali" : ""}
                            </div>
                        </div>
                        <div className="row">
                            <img className="mr-auto ml-auto mb-5" src="/dashboard/images/We4Guestbrand.png" style={{marginTop:"20%", width: "50px"}}/>
                        </div>
                    </a>
                </div>
                <div className="hotel-image" style={{backgroundImage: 'url('+data.hotel.copertina+')'}}>
                    <div className="overlap-info">
                    <div id="hotelcontent" className="hotel-content">
                            <Row style={{alignItems: "center"}}>
                                <Col className="col-md-4">
                                    <img className="img-fluid" src={data.hotel.logo}></img>
                                </Col>
                                <Col className="col-md-4">
                                    <p className="rubik" style={{fontWeight: "bold", fontSize: "2.5vh", color: "#475F7B",}}>{data.hotel.nome}</p>
                                    <p className="rubik" style={{color: "#475F7B", fontSize: "1.5vh"}}>{data.hotel.indirizzo} - {data.hotel.cap} {data.hotel.citta}</p>
                                </Col>
                                <Col className="col-md-4">
                                    <div style={{ marginRight: "5vh" }} className="text-right">
                                        <a href={data.hotel.link_prenotazione[0].link} target="_blank" className="btn btn-red w-auto" style={{width: "156px !important"}} >Prenota ora</a>
                                    </div>
                                </Col>
                            </Row>
                            </div>
                    </div>
                </div>
            </div>
            <div style={{backgroundColor: "white"}} className="row">
                <div className="mobile-widget row" style={{paddingTop:"3%", paddingBottom:"3%", marginTop: "3%", borderTop: "1px solid #C7CFD6", borderBottom: "1px solid #C7CFD6"}}>
                    <div style={{marginLeft: "6%"}} className="col-3 d-flex justify-content-center ">
                        <iframe  id="mobileFixediframe" style={{border: "none", borderRadius:"0px 6px 6px 0px !important;"}} frameborder="0" allowtransparency="true" scrolling="no" height="70px" width="70px%" src={"/widget_mobile.php?master="+master+"&lingua=it&posizione=sx#"} />
                    </div>
                <div className="col d-flex justify-content-center rubik">
                    <p style={{color: "#475F7B", fontSize: "18px", lineHeight: "1.2", paddingTop: "6%", textAlign: "left"}} className="mg-b-0-center p-0"><b>{data.widget.tot_recensioni}</b><br/>recensioni su <br/>{data.widget.num_portali} portali</p>
                </div>
                </div>
                <div className="col-md-8 left-content">
                <div className="container">
                <div className="row" style={{marginTop: "26px", color: "#475F7B"}}>
                    <div className="container">
                    <p className="rubik" style={{fontSize: "20px"}}>Ordina per</p>
                    </div>
                </div>
                <div className="row">
                <div className="col-6">
                <UncontrolledDropdown>
                    <DropdownToggle className="dropdown-ordina btn-block" caret>
                        {dataReputazione.dataPiùRecente}
                    </DropdownToggle>
                    <DropdownMenu style={{width: "100%", backgroundColor: "white !important"}}>
                        <DropdownItem onClick={()=> {
                            let sorted = dataReputazione.rewiews.reduce((a,b) => { return new Date(b.data) < new Date(a.data) ? b : a});
                            }} className="bg-white" style={{color:"#F80555"}}>{dataReputazione.dataMenoRecente}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                </div>
                <div className="col-6">
                <UncontrolledDropdown>
                    <DropdownToggle className="dropdown-lingue btn-block" caret>
                        Tutte le lingue
                    </DropdownToggle>
                </UncontrolledDropdown>
                </div>
                </div>
                {rewiewsToShow.map((object) => {
                        return (<div style={{marginTop: "2%"}} className="card card-recensioni ">
                        <div className="row card-row">
                            <div id={object.id} className="col-4  card-info">
                                <div className="image-recensione d-flex justify-content-center text-center">
                                    {/*<img src={rewiew.icona} className="img-fluid"></img>*/}
                                <div>
                                   <div className="circle-text">
                                       {object.voto} %
                                   </div>
                                </div>
                                </div>
                                <div style={{color: "#727E8C"}} className="recensione-info d-flex justify-content-center text-center">
                                   <p style={{fontWeight: "700"}}>{object.nome}</p>
                                   <p>{object.portale}</p>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="container rubik" style={{marginTop: "5%",  borderBottom: "1px solid #C7CFD6",color: "#B3B3B3", fontStyle: "italic"}}>
                                    <p style={{fontWeight: "400"}}>Recensione inviata: <div style={{fontWeight: "700", display:"inline"}}>{object.data}</div></p>
                                    <p style={{fontWeight: "400"}}>Data dell'esperienza: <div style={{fontWeight: "700", display:"inline"}}></div></p>
                                </div>
                                <div className="container rubik" style={{marginTop: "5%"}}>
                                    <p style={{color:"#727E8C", fontWeight:"400"}}>{object.testo}</p>
                                </div>
                            </div>
                        </div>
                    </div>);
                    })}
                <div className="row justify-content-center">
                    <input type="button" onClick={() => dispatch(showMore())} className="btn btn-block btn-red w-auto" style={{marginTop: "5%", marginBottom: "5%"}} value={"Visualizza altre Recensioni ("+remainingRewiews+")"}/>
                </div>
                </div>
                </div>
                <div className="col-md-4 right-content">
                    <div className="container">
                        <div style={{marginTop: "32px", paddingLeft: "10%"}} className="row">
                            <h5 className="rubik" style={{color: "#475F7B"}}>Valutazione Media</h5>
                        </div>
                    </div>
                    <hr style={{border: "1px solid #E3E7EA", marginTop: "20px", width:"100%"}}></hr>
                    <div className="container">
                        <div className="col" style={{paddingTop:"5%", paddingBottom: "5%"}}>
                            {data.portali.map((nomePortale) =>{
                                return(
                                    <div className="row">
                                        <div className="percentage-row">
                                            <p className="guest-name">{nomePortale.nome}</p>
                                            <hr style={{border: "1px solid #E3E7EA", marginTop: "20px", width:"50%", display:"inline-block"}}></hr>
                                            <p className="percentage">{nomePortale.media}%</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{marginTop: "32px", paddingLeft: "10%"}} className="row">
                        <h5 className="rubik" style={{color: "#475F7B"}}>Criteri di Rating</h5>
                    </div>
                    <hr style={{border: "1px solid #E3E7EA", marginTop: "20px", width:"100%"}}></hr>
                        <div style={{paddingLeft: "8%"}} className="col justify-content-center">
                            <div className="container">
                                <div className="row custom-progress-head" style={{ fontSize: "12px"}}>
                                    <div style={{fontFamily: "Rubik, sans-serif", fontSize: "14px", color: "#727E8C"}} className="col">
                                        {data.punteggi.map((punteggio) => {
                                            if(punteggio.valore <= 20){
                                                const width = punteggio.value*5;
                                                return(
                                                    <div>
                                                        <div style={{marginTop:"3%"}} className="row">
                                                            <div className="col p-0">{punteggio.nome}</div>
                                                            <div className="col text-right">{punteggio.valore} %</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: width+"%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    );
                                            }else if(punteggio.valore <= 40){
                                                const new_value = punteggio.valore - 20;
                                                const width = new_value * 5;
                                                return(
                                                    <div>
                                                        <div style={{marginTop:"3%"}} className="row">
                                                            <div className="col p-0">{punteggio.nome}</div>
                                                            <div className="col text-right">{punteggio.valore} %</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: width+"%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    );
                                            }else if(punteggio.valore <= 60){
                                                const new_value = punteggio.valore - 40;
                                                const width = new_value * 5;
                                                return(
                                                    <div>
                                                        <div style={{marginTop:"3%"}} className="row">
                                                            <div className="col p-0">{punteggio.nome}</div>
                                                            <div className="col text-right">{punteggio.valore} %</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: width+"%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    );
                                            }else if(punteggio.valore <= 80){
                                                const new_value = punteggio.valore - 60;
                                                const width = new_value * 5;
                                                return(
                                                    <div>
                                                        <div style={{marginTop:"3%"}} className="row">
                                                            <div className="col p-0">{punteggio.nome}</div>
                                                            <div className="col text-right">{punteggio.valore} %</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: width+"%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "0%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    );
                                            }else {
                                                const new_value = punteggio.valore - 80;
                                                const width = new_value * 5;
                                                return(
                                                    <div>
                                                        <div style={{marginTop:"3%"}} className="row">
                                                            <div className="col p-0">{punteggio.nome}</div>
                                                            <div className="col text-right">{punteggio.valore} %</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: "100%"}} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="progress col p-0 custom-progress">
                                                                <div className="progress-bar" style={{width: width+"%" }} aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    );
                                            }
                                        })}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div style={{marginTop: "60px", paddingLeft: "10%"}} className="row">
                            <h5 className="rubik" style={{color: "#475F7B"}}>Info e Contatti</h5>
                        </div>
                        <hr style={{border: "1px solid #E3E7EA", marginTop: "20px", width:"100%"}}></hr>
                        <div className="col justify-content-center" style={{color: "#475F7B", fontFamily: "Rubik, sans-serif"}}>
                            {data.hotel.facebook !== "" &&
                            <div className="container embeded-responsive" style={{paddingLeft: "5%"}}>
                                <p className="mb-0">Facebook</p>
                                <iframe className="embeded-responsive-item"src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F"+data.hotel.facebook+"&tabs&width=900&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"} style={{border:"none", width: "100%" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            </div>
                            }
                            <div className="container" style={{paddingLeft: "5%"}}>
                            <p className="mb-0">Come Raggiungerci</p>
                            <MapWithAMarker
                                containerElement={<div style={{ height: `400px` }} />}
                                mapElement={<div style={{ height: `60%` }} />}
                                            />
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
    }
}


export default ModuloReputazione;