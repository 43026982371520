import React from 'react';
import { useSelector } from 'react-redux';
import { Fade } from 'reactstrap'
import { getBootState, getBootStatus } from '../../features/bootstrapSlice'
import parse from 'html-react-parser';

function Terms(props) {

    const bootData = useSelector(getBootState);
    const status = useSelector(getBootStatus);

    return (
        <>
            {!status.loading 
                ?
                <Fade className="m-3">
                    {parse(bootData.posto_master.profilazione)} 
                </Fade>
                :
                <></>
            }
        </>
    )
}

export default Terms