import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getReputazione = createAsyncThunk("moduloReputazione", async (endpoint) =>{
	try {
        const response = await fetch(endpoint);
        const json = await response.json();
        return json;
    } catch (e) {
        throw Error(e);
    }
})


export const repSlice = createSlice({
	name: 'moduloReputazione',
	initialState: {
		loading: true,
		error: "",
		data: [],
		rewiews: [],
		numberRewsShown: 5,
		dataMenoRecente: "Data meno Recente",
		dataPiùRecente: "Data più Recente",

	},
	reducers: {
		showMore(state, action){
			if(state.numberRewsShown +3 <= state.rewiews.length){
				state.numberRewsShown  +=3;
			}else{
				state.numberRewsShown = state.rewiews.length;
			}
		},

		orderRews(state, action){
			console.log(action.payload);
		}
	},
	extraReducers : {
		[getReputazione.pending]: state => {state.loading = true},
		[getReputazione.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getReputazione.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
			state.rewiews = state.data.recensioni;
			
		}
	}


});

export default repSlice.reducer;

export const { showMore, orderRews } = repSlice.actions;

export const getRepState = state => state.repSlice;