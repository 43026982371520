//import React, { useState } from 'react'
import { Fade } from 'reactstrap'
import openNav from '../../functions/openNav'
import { useSelector } from 'react-redux'
import User from './icons/User.svg'
import Menu from './icons/Menu.svg'
import { Link } from 'react-router-dom'
import { Navbar as Nav, NavbarBrand } from 'reactstrap'
import { getBootState } from '../../features/bootstrapSlice'
import { withRouter } from 'react-router-dom';
import Lingue from './Lingue'

function Navbar(props) {
    const data = useSelector(getBootState);
    //hide navbar in path /questionario
    const { location } = props;
    if(location.pathname.match("/questionario") || location.pathname.match("/moduloreputazione") || location.pathname.match('/anteprimaquestionario')){
        return null;
    }
    if(location.pathname.match("/(.*)/subscribe.html") || location.pathname.match("/(.*)/unsubscribe.html")){ //mostro solo il logo
        return (
            <Fade>
                <Nav light expand="md">
                    <Lingue />
                    <NavbarBrand className="mx-auto"><img src={data.logomaster} style={{maxHeight: '40px', maxWidth: '250px'}} alt=""/></NavbarBrand>
                </Nav>
            </Fade>
        )
    }
    return (
        <Fade> 
            <Nav light expand="md"> 
                {!props.open
                    ?
                    <img src={Menu} onClick={() => { openNav(); props.setOpen() }} className="ml-2" alt=""/>
                    :
                    <img src={Menu} className="ml-2" alt=""/>
                }
                <NavbarBrand className="mx-auto"><Link to={"/"}><img src={data.logomaster} style={{maxHeight: '40px', maxWidth: '250px'}} alt=""/></Link></NavbarBrand>
                {document.cookie.match('connesso') 
                ?
                    <Link to="/profile"><img src={User} className="mr-2" alt=""/></Link>
                :
                    <div className="mr-4"></div>
                }
            </Nav>
        </Fade>
    )
}


export default withRouter(Navbar);