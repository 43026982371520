import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getQuestionario = createAsyncThunk("questionario", async (endpoint) =>{
	const responce = await fetch(endpoint, {
		method: 'GET'
	  });

	if (!responce.ok) {
		throw Error(responce.statusText);
	}
	const json = await responce.json();
	
	return json;
});

export const sendQuestionario = createAsyncThunk("data", async (questData) =>{
	console.log(JSON.stringify(questData));
	try {
        const response = await fetch("/questionario_act.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
				mode: "cors"
            },
            body: JSON.stringify(questData)
        });

        const json = await response.json();

        return json;
    } catch (e) {
        throw Error(e);
    }
});


export const questSlice = createSlice({
	name: 'questionario',
	initialState: {
		loading: true,
		error: "",
		data: [],
		questions: {},
		subQuestions: [],
		answers: {},
	},
	reducers: {
		setSubAnswers: (state, action) => {

			if(action.payload.qt === 'SN'){
				//prendo l'array con tutte le domande { id: id , sottodomande: [] }
				const questions  = state.questions
				//vedo se trovo
				let question = questions[action.payload.id];
				
				//trovo la domanda con quell'id
				if(question != null || question !== undefined){
					//verifico se la risposta data alla domanda mostra la/e sottodomanda/e
					question.forEach(subquestion => {
						if(subquestion.mostrasottodomanda.includes(action.payload.textAnswer[0]) && !state.subQuestions.find(o => o.subquestions.doid === subquestion.doid)){
							state.subQuestions.push({id: action.payload.id , subquestions: subquestion});
						}
					});
	
					state.subQuestions.forEach((subquestion, index) =>{
						if(!subquestion.subquestions.mostrasottodomanda.includes('')){
							if((!subquestion.subquestions.mostrasottodomanda.includes(action.payload.textAnswer[0]) && subquestion.id === action.payload.id)){
								let index = state.subQuestions.indexOf(subquestion);
								switch(subquestion.subquestions.tipologia){
									case 'RV':
										if(state.answers["voto"+subquestion.subquestions.doid]){
											delete state.answers["voto"+subquestion.subquestions.doid];
										}
									break;
	
									case 'RL':
										if(state.answers["libero"+subquestion.subquestions.doid]){
											delete state.answers["libero"+subquestion.subquestions.doid];
										}
									break;
	
									default:
										console.log("Entered");
										if(state.answers["opzione"+subquestion.subquestions.doid]){
											delete state.answers["opzione"+subquestion.subquestions.doid];
		
										}
									break;
								}
								return [state.subQuestions.splice(index, 1)];
							}
						}
					})
				}
			}else if(action.payload.qt === 'RO'){
				const questions  = state.questions;

				let question = questions[action.payload.id];

				if(question != null || question !== undefined){
					question.forEach(subquestion => {
						if(subquestion.mostrasottodomanda.join('').includes(action.payload.vote) && !state.subQuestions.find(o => o.subquestions.doid === subquestion.doid)){
							state.subQuestions.push({id: action.payload.id , subquestions: subquestion});
						}
					});

					var subquestions = [...state.subQuestions];
	
					subquestions.forEach(subquestion =>{
							if(subquestion.id === action.payload.id){;
								if(!subquestion.subquestions.mostrasottodomanda.join('').includes(action.payload.vote)){
									let index = state.subQuestions.indexOf(subquestion);
									switch(subquestion.subquestions.tipologia){
										case 'RV':
											if(state.answers["voto"+subquestion.subquestions.doid]){
												delete state.answers["voto"+subquestion.subquestions.doid];
											}
										break;
		
										case 'RL':
											if(state.answers["libero"+subquestion.subquestions.doid]){
												delete state.answers["libero"+subquestion.subquestions.doid];
											}
										break;
		
										default:
											console.log("Entered");
											if(state.answers["opzione"+subquestion.subquestions.doid]){
												delete state.answers["opzione"+subquestion.subquestions.doid];
			
											}
										break;
									}

									return [state.subQuestions.splice(index, 1)];
								}
							}
							/*if((subquestion.subquestions.mostrasottodomanda.join('').includes(action.payload.vote) && subquestion.id === action.payload.id) && action.payload.textAnswer === 'unck'){
								let found = subquestion.op.indexOf(action.payload.vote);
				
								if(found !== -1){
									subquestion.op.splice(found, 1);
									state.subQuestions[index] = subquestion;
								}
								if(subquestion.op.length == 0){
									state.subQuestions.splice(index, 1);
								}
							}*/
					});
				}

			}else{
				const questions  = state.questions

				let question = questions[action.payload.id];

				//console.log(current(question));

				if(question != null || question !== undefined){
					question.forEach(subquestion => {
						if(subquestion.mostrasottodomanda.includes(action.payload.vote) && !state.subQuestions.find(o => o.subquestions.doid === subquestion.doid)){
							state.subQuestions.push({id: action.payload.id , subquestions: subquestion});
						}
					});
				}

				subquestions = [...state.subQuestions];

				/*for(let subquestion of subquestions){
					console.log("entered");
					if(action.payload.id === subquestion.id){
						if(!subquestion.subquestions.mostrasottodomanda.includes(action.payload.vote)){
							let index = subquestions.indexOf(subquestion);
							state.subQuestions.splice(index, 1);
						}
					}
				}*/

				subquestions.forEach(subquestion  =>{
					if(action.payload.id === subquestion.id){
						if(!subquestion.subquestions.mostrasottodomanda.includes(action.payload.vote)){
							switch(subquestion.subquestions.tipologia){
								case 'RV':
									if(state.answers["voto"+subquestion.subquestions.doid]){
										delete state.answers["voto"+subquestion.subquestions.doid];
									}
								break;

								case 'RL':
									if(state.answers["libero"+subquestion.subquestions.doid]){
										delete state.answers["libero"+subquestion.subquestions.doid];
									}
								break;

								default:
									if(state.answers["opzione"+subquestion.subquestions.doid]){
										delete state.answers["opzione"+subquestion.subquestions.doid];
									}
							}
							let index = state.subQuestions.indexOf(subquestion);
							return [state.subQuestions.splice(index, 1)];
						}
					}
				});

			}
		},

		setAnswers: (state, action) => {
			let answers = {...state.answers, [action.payload.name]: action.payload.vote}
				state.answers = answers;
		},
	},
	extraReducers : {
		[getQuestionario.pending]: state => {state.loading = true},
		[getQuestionario.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getQuestionario.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
			state.data.domande.map((domanda) =>{
					if(!domanda.sottodomande.length == 0){
						state.questions[domanda.doid] = domanda.sottodomande;
					}
			});
			
			//state.questions = subanswer;

			//mostro le sottodomande sempre visibili
			/*state.questions.forEach(subquestion =>{
				subquestion.sottodomande.forEach(question => {
					if(subquestion.id != -1){
						if(question.mostrasottodomanda.includes('')&& !state.subQuestions.find(o => o.subquestions.doid === question.doid)){
							state.subQuestions.push({id: subquestion.id, subquestions: question});
						}
					}
				})
			});*/

			//console.log(state.questions["287"]);
		}

	}


});

export const { setSubAnswers, setAnswers, getAnswers } = questSlice.actions;

export default questSlice.reducer;

export const getQuestState = state => state.quest;

export const getSubquestionsState = state => state.quest.subQuestions;