import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getPlace = createAsyncThunk("place", async (endpoint) => {
	const responce = await fetch(endpoint);
	if (!responce.ok) {
		throw Error(responce.statusText);
	}
	const json = await responce.json();
	return json;
})


export const placeSlice = createSlice({
	name: 'place',
	initialState: {
		loading: true,
		error: "",
		data: [],
		bookNumber: 0
	},
	reducers: {
		addNumber(state, action) {
			state.bookNumber++
		},
		removeNumber(state, action) {
			if(state.bookNumber > 0)
			state.bookNumber--
		}
	},
	extraReducers: {
		[getPlace.pending]: state => { state.loading = true },
		[getPlace.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		},
		[getPlace.fulfilled]: (state, action) => {
			state.loading = false;
			state.error = "";
			state.data = action.payload;
		}

	}


});

export default placeSlice.reducer;

export const { addNumber, removeNumber } = placeSlice.actions

export const getBookNumber = state => state.place.bookNumber

export const getPlaceState = state => state.place.data;

export const getFullPlaceState = state => state.place;