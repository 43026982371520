import React, { useState } from 'react'
import { Col, Container, Collapse, Fade, Row, Dropdown , DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import closeNav from '../../functions/closeNav'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Home from './icons/home.svg'
import ChevronDown from './icons/chevron_down.svg'
import ChevronUp from './icons/chevron_up.svg'
import Info from './icons/Info2.svg'
import User from './icons/User_side.svg'
import Pin from './icons/Pin map.svg'
import Phone from './icons/phone2.svg'
import Phone3 from './icons/phone3.svg'
import Chat from './icons/chat2.svg'
import Orders from './icons/edit2.svg'
import Web from './icons/Web.svg'
import Whatsapp from './icons/whatsapp.svg'
import Favorite from './icons/Like cuore.svg'
import { getBootState } from '../../features/bootstrapSlice';
import { getHomeState } from '../../features/homepageSlice';
import getCookie from '../../functions/getCookie'
import validateURL from '../../functions/validateURL'


function Sidenav(props) {
    //gestione lingue
    const [lingua, setLingua] = useState(getCookie('lingua') ? getCookie('lingua') : 'IT');

    const setLinguaF = async (lingua) => {
        
        await fetch('/parser.php?lingua='+lingua, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: 'cors'
            },
            body: ""
        })

        window.location.reload(false)
        setLingua(lingua)
    }

    //dropdown lingue
    const [dropdownOpen, setdropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setdropdownOpen(!dropdownOpen);
    }

    //collapse numeri utili
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
        //if (!isOpen)
            //document.getElementById('add-active').classList.add("active");
        //else
            //document.getElementById('add-active').classList.remove("active");
    }

    const bootData = useSelector(getBootState);
    const data = useSelector(getHomeState);

    

    if(bootData.posto_master){
        return (
            <Fade>
                <div id="mySidenav" className="sidenav">
                    <div className="sidenav-top-box">
                        <Row>
                            <Col>
                                <Dropdown nav={true} className="label-lang" isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle tag="span" className="label-lang">
                                        {lingua}
                                    </DropdownToggle >
                                    <DropdownMenu style={{minWidth:"auto"}}>
                                        {bootData.lingue && bootData.lingue.map((lingua, index) =>
                                            <DropdownItem key={index} tag="span" className="p-3 label-lang" onClick={() => setLinguaF(lingua.id)}>
                                                <img src={"/dashboard/images/"+lingua.flag} className="mr-2" width="20" alt=""/>
                                                <span>{lingua.id}</span>
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </Dropdown >
                            </Col>
                            {bootData.posto_master.latitudine !== "0" && bootData.posto_master.longitudine !== "0" &&
                                <Col>
                                    <a href={validateURL("https://maps.google.com/maps?daddr="+bootData.posto_master.latitudine+","+bootData.posto_master.longitudine+"&amp;ll=")} target="_blank" className="p-0" rel="noreferrer"><img src={Pin} className="icon-s" alt=""/></a>
                                </Col>
                            }
                            {bootData.posto_master.telefono !== "" &&
                                <Col>
                                    <a href={"tel:"+bootData.posto_master.telefono} className="p-0"><img src={Phone} className="icon-s" alt=""/></a>
                                </Col>
                            }
                            {bootData.posto_master.whatsapp !== "" &&
                                <Col>
                                    <a href={validateURL(bootData.posto_master.whatsapp)} target="_blank" className="p-0"><img src={Whatsapp} className="icon-s" rel="noopener" alt=""/></a>
                                </Col>
                            }
                            {bootData.posto_master.email !== "" &&
                            <Col>
                                <a href={"mailto:"+bootData.posto_master.email} className="p-0"><img src={Chat} className="icon-s" alt=""/></a>
                            </Col>
                            }
                            {bootData.posto_master.sito !== "" &&
                            <Col>
                                <a href={validateURL(bootData.posto_master.sito)} target="_blank" className="p-0" rel="noreferrer"><img src={Web} className="icon-s" alt=""/></a>
                            </Col>
                            }
                        </Row>
                    </div>
                    {/*
                    <div className="sidenav-logo text-center">
                        <img src={bootData.logomaster} id="img-logo" className="pt-5 mt-2 pb-3" alt=""/>
                        
                        <span className="d-block">
                            <img src={Pin} style={{ width: "14px", marginRight: "5px" }} alt=""/>
                            <small>Torino**</small>
                        </span>
                    </div>
                    */}
                    <img  style={{ backgroundColor: "#f5f5f5" }} src={bootData.logomaster} className="w-100 p-5" alt=""/>

                    <div className="sidenav-section">
                        <Link to={"/"} className="border-nav border-bottom" onClick={() => { closeNav(); props.setOpen() }}>
                            <img src={Home} className="icon-p" alt=""/>
                            <span className="side-text">{data.lotesto17}</span>
                        </Link>
                        <Link to={"/info"} className="border-nav border-bottom" onClick={() => { closeNav(); props.setOpen() }}>
                            <img src={Info} className="icon-p" alt=""/>
                            {bootData.master === '1551'
                            ?
                                <span className="side-text">Info Discover Turin</span>
                            :
                                <span className="side-text">Hotel Info</span>
                            }
                        </Link>
                        {document.cookie.match('connesso') &&
                        <>
                            <Link to={"/profile"} className="border-nav border-bottom" onClick={() => { closeNav(); props.setOpen() }}>
                                <img src={User} className="icon-p" alt=""/>
                                <span className="side-text">{data.testo94}</span>
                            </Link>
                            <Link to={"/orders"} className="border-nav border-bottom" onClick={() => { closeNav(); props.setOpen() }}>
                                <img src={Orders} className="icon-p" alt=""/>
                                <span className="side-text">{data.testo110}</span>
                            </Link>
                            <Link to={"/favorite"} className="border-nav border-bottom" onClick={() => { closeNav(); props.setOpen() }}>
                                <img src={Favorite} className="icon-p" alt=""/>
                                <span className="side-text">{data.testo95}</span>
                            </Link>
                        </>
                        }
                        <a className="border-nav border-bottom" href="#" onClick={toggle}>
                            <img src={Phone3} className="icon-p" alt=""/>
                            <span id="add-active" className="side-text">{data.mltesto1}</span>
                            <small className="pl-4">
                                {!isOpen
                                    ?
                                    <img src={ChevronDown} alt=""/>
                                    :
                                    <img src={ChevronUp} alt=""/>
                                }
                            </small>
                        </a>
                        <Collapse isOpen={isOpen} style={{ backgroundColor: "#f5f5f5" }}>
                            <Container className="pb-2">
                                <span style={{ fontWeight: "600" }}>Numero Unico Europeo 112</span>
                                {/* <span style={{ fontWeight: "600" }}>{data.mltesto14} 113</span><br />
                                <span style={{ fontWeight: "600" }}>{data.mltesto15} 115</span><br />
                                <span style={{ fontWeight: "600" }}>{data.mltesto2} 118</span> */}
                            </Container>
                        </Collapse>
                        <div className="m-4">
                            <span>Powered by</span><br />
                            <img src={bootData.logo} className="side-logo" alt=""/>
                        </div>
                    </div>
                </div>
            </Fade >
        )
    }else {
        return null
    }
}

export default Sidenav;