import React, { /*useState*/ } from 'react';
import { Col, Row, Fade } from 'reactstrap'
// import { Link } from 'react-router-dom';
// import close from '../Mobile/icons/close.svg';
import ServicePanel from './ServicePanel';
import CartPanel from './CartPanel';
import { useSelector } from 'react-redux'
import { getCart } from '../../features/serviceSlice';

function ServicesPanel({homeData, bootData, servizioId, setServizioId}) {
    
    const cart = useSelector(getCart);

    return (
        <Fade id="servicePanel" className="sidenavright" style={{backgroundColor: 'rgba(128,128,128,0.7)'}}>
            <Row className='h-100'>
                <Col className='col-4' onClick={() => setServizioId(false)}></Col>

                {cart.itemsAdded.length > 0 
                ?
                    <Fade className='col-4 bg-white' style={{height: '100vh', overflow: 'auto'}}>
                        <CartPanel bootData={bootData} homeData={homeData} setServizioId={setServizioId}/>
                    </Fade>
                :
                    <Col onClick={() => setServizioId(false)}></Col>
                }

                <Col id="servizioPanel" className='col-4 bg-white border-left' style={{ height: '100vh', overflow: 'auto'}}>
                    <ServicePanel bootData={bootData} homeData={homeData} servizioId={servizioId} setServizioId={setServizioId}/>
                </Col>
            </Row>
            
        </Fade>
    )
}

export default ServicesPanel