import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import remove from 'remove_index'

export const getService = createAsyncThunk("service", async(endpoint) => {
    const responce = await fetch(endpoint);
    if (!responce.ok) {
        throw Error(responce.statusText);
    }
    const json = await responce.json();
    return json;
})


export const serviceSlice = createSlice({
    name: 'servizio',
    initialState: {
        loading: true,
        error: "",
        data: [],
        cart: {
            total: 0,
            dataDal: '',
            dataAl: '',
            items: [],
            itemsAdded: []
        },
    },
    reducers: {
        insertItem(state, action) {
            let index = state.cart.items.findIndex(item => item.id === action.payload.id)
            if (index !== -1) {
                state.cart.items[index].quantity = state.cart.items[index].quantity + 1
                state.cart = {
                    ...state.cart,
                    items: [
                        ...state.cart.items
                    ]
                }
            } else {
                let item = {
                    ...action.payload,
                    quantity: 1
                }
                state.cart = {
                    ...state.cart,
                    items: [
                        ...state.cart.items,
                        {
                            ...item
                        }
                    ]
                }
            }
            state.error = ""
        },
        deleteItem(state, action) {

            const indexToRemove = state.cart.items.findIndex(item => item.id === action.payload.id)
            let filteredCart = state.cart.items

            if (indexToRemove !== -1) {
                if (filteredCart[indexToRemove].quantity === 1)
                    filteredCart = remove(filteredCart, indexToRemove)
                else
                    filteredCart[indexToRemove].quantity = filteredCart[indexToRemove].quantity - 1
                state.cart = {
                    ...state.cart,
                    items: [
                        ...filteredCart
                    ]
                }
            }
        },
        addItem(state, action) {
            
            if(action.payload.ricqua === '0') {
                serviceSlice.caseReducers.insertItem(state, action)
            }

            const index = state.cart.items.findIndex(item => item.id === action.payload.id)
            const flag = state.cart.itemsAdded.findIndex(item => item.id === action.payload.id)
            if (flag === -1 && index !== -1) {
                // if (typeof state.cart.items[index].giorniDal !== "undefined" && typeof state.cart.items[index].oreDalle !== "undefined") {
                    state.cart = {
                        ...state.cart,
                        itemsAdded: [
                            ...state.cart.itemsAdded,
                            {...state.cart.items[index] }
                        ]
                    }
                    serviceSlice.caseReducers.updateTotal(state, action)
                    state.error = ""
                // } else {
                //     state.error = "Per prenotare un servzio, compilare tutti i dati richiesti (orario, data)";
                // }
            } else {
                state.error = "Per prenotare un servzio, compilare tutti i dati richiesti (orario, data)";
            }
        },
        removeItem(state, action) {

            if(action.payload.ricqua === '0') {
                serviceSlice.caseReducers.deleteItem(state, action)
            }
            
            const index = state.cart.itemsAdded.findIndex(item => item.id === action.payload.id)
            const filteredCart = state.cart.itemsAdded
            if (index !== -1) {
                
                remove(filteredCart, index)

                state.cart = {
                    ...state.cart,
                    itemsAdded: [
                        ...filteredCart
                    ]
                }
                serviceSlice.caseReducers.updateTotal(state, action)
            }
        },
        updateGiorniDal(state, action) {
            const index = state.cart.items.findIndex(item => item.id === action.payload.elemento.id)
            if (index !== -1) {
                state.cart.items[index] = {
                    ...state.cart.items[index],
                    giorniDal: action.payload.giorni
                }
            }else { //inserisco le ore prima di creare l'elemento
                state.cart.items = [
                    ...state.cart.items,
                    {
                        ...action.payload.elemento,
                        giorniDal: action.payload.giorni,
                        quantity: 1
                    }
                ]
            }
            state.error = ""
            /*
            state.cart.items[action.payload.index] = {
                ...state.cart.items[action.payload.index],
                giorni: action.payload.giorni
            }
            */
        },
        updateGiorniAl(state, action) {
            const index = state.cart.items.findIndex(item => item.id === action.payload.elemento.id)
            if (index !== -1) {
                state.cart.items[index] = {
                    ...state.cart.items[index],
                    giorniAl: action.payload.giorni
                }
            }else { //inserisco le ore prima di creare l'elemento
                state.cart.items = [
                    ...state.cart.items,
                    {
                        ...action.payload.elemento,
                        giorniAl: action.payload.giorni,
                        quantity: 1
                    }
                ]
            }
            state.error = ""
            /*
            state.cart.items[action.payload.index] = {
                ...state.cart.items[action.payload.index],
                giorni: action.payload.giorni
            }
            */
        },
        updateOreDalle(state, action) {
            const index = state.cart.items.findIndex(item => item.id === action.payload.elemento.id)
            if (index !== -1) {
                state.cart.items[index] = {
                    ...state.cart.items[index],
                    oreDalle: action.payload.ore
                }
            }else { //inserisco le ore prima di creare l'elemento
                state.cart.items = [
                    ...state.cart.items,
                    {
                        ...action.payload.elemento,
                        oreDalle: action.payload.ore,
                        quantity: 1
                    }
                ]
            }
            state.error = ""
        },
        updateOreAlle(state, action) {
            const index = state.cart.items.findIndex(item => item.id === action.payload.elemento.id)
            if (index !== -1) {
                state.cart.items[index] = {
                    ...state.cart.items[index],
                    oreAlle: action.payload.ore
                }
            }else { //inserisco le ore prima di creare l'elemento
                state.cart.items = [
                    ...state.cart.items,
                    {
                        ...action.payload.elemento,
                        oreAlle: action.payload.ore,
                        quantity: 1
                    }
                ]
            }
            state.error = ""
        },
        updateCamera(state, action) {
            const index = state.cart.items.findIndex(item => item.id === action.payload.elemento.id)
            if (index !== -1) {
                state.cart.items[index] = {
                    ...state.cart.items[index],
                    camera: action.payload.camera
                }
            }else { //inserisco la camera prima di creare l'elemento
                state.cart.items = [
                    ...state.cart.items,
                    {
                        ...action.payload.elemento,
                        camera: action.payload.camera,
                        quantity: 1
                    }
                ]
            }
            state.error = ""
            /*
            state.cart.items[action.payload.index] = {
                ...state.cart.items[action.payload.index],
                camera: action.payload.camera
            }
            */
        },
        emptyCart(state, action) {
            state.error = ""
            state.cart = {
                total: 0,
                dataDal: '',
                dataAl: '',
                items: [],
                itemsAdded: []
            }
        },
        updateTotal(state, action) {
            var totale = 0

            state.cart.itemsAdded.forEach(element => {
                
                var days = 1;
                // // calcola i giorni - caso giorni per ogni elemento
                // if(typeof element.giorniAl !== "undefined"){

                //     var date1 = new Date(element.giorniDal);
                //     var date2 = new Date(element.giorniAl);
                    
                //     // To calculate the time difference of two dates
                //     var Difference_In_Time = date2.getTime() - date1.getTime();
                    
                //     // To calculate the no. of days between two dates
                //     days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                // }
                // calcola i giorni - caso giorni per tutto l'ordine
                if(state.cart.dataDal !== "" && state.cart.dataAl !== ''){

                    var date1 = new Date(state.cart.dataDal);
                    var date2 = new Date(state.cart.dataAl);
                    
                    // To calculate the time difference of two dates
                    var Difference_In_Time = date2.getTime() - date1.getTime();
                    
                    // To calculate the no. of days between two dates
                    days = (Difference_In_Time / (1000 * 3600 * 24)) + 1;
                }


                if(element.sconto > 0){
                    totale = parseFloat(totale) + ((parseFloat(element.sconto) * element.quantity) * days)
                }else {
                    totale = parseFloat(totale) + ((parseFloat(element.prezzo) * element.quantity) * days)
                }

            });

            state.cart = {
                ...state.cart,
                total: totale.toFixed(2)
            }
        }, updateDataDal(state, action) {
            state.cart.dataDal = action.payload.data;
            serviceSlice.caseReducers.updateTotal(state, action)
        }, updateDataAl(state, action){
            state.cart.dataAl = action.payload.data;
            serviceSlice.caseReducers.updateTotal(state, action)
        }
    },
    extraReducers: {
        [getService.pending]: state => { state.loading = true },
        [getService.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getService.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = "";
            state.data = action.payload;
        }
    }
});

export default serviceSlice.reducer;

export const { emptyCart, insertItem, deleteItem, addItem, removeItem, updateGiorniDal, updateGiorniAl, updateOreDalle, updateOreAlle, updateCamera, updateDataDal, updateDataAl } = serviceSlice.actions

export const getCart = state => state.service.cart;

export const getError = state => state.service.error;

export const getServiceState = state => state.service.data.servizio;

export const getServiceStatus = state => state.service.loading;