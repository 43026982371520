import React, { useState } from 'react';
import { Col, Fade, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import { getHomeState } from '../../features/homepageSlice';

function SearchBar({ searchQuery, setSearchQuery}) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const data = useSelector(getHomeState);
    return (
        <Fade>
            <Row className="py-3">
                <Col>
                    <input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} type="search" onFocus={toggle} placeholder={data.testo88} />
                </Col>
            </Row>
        </Fade>
    )
}

export default SearchBar