import React from 'react'
import { Button, Fade } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getHomeState } from '../../features/homepageSlice';
import { useSelector } from 'react-redux'

function Thankyou() {
    const homeData = useSelector(getHomeState);
    return (
        <Fade>
            <div className="text-center w-100" style={{position: "absolute", top: "30%"}}>
                <h1>{homeData.testo163}</h1><br/>
                <span>{homeData.testo164}</span><br/>
                <Link to="/orders"><Button className="btn-first mt-4 px-4 py-2 font-weight-bold">{homeData.testo110}</Button></Link><br/>
                <Link to="/"><Button className="btn-light mt-2">{homeData.testo165}</Button></Link>
            </div>
        </Fade>
    )
}

export default Thankyou