import React, { useEffect, useState } from 'react';
import '../../functions/openNav'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Fade, Row, Collapse } from 'reactstrap'
import { deleteItem, insertItem, getService, getServiceState, getCart, addItem, removeItem, getServiceStatus } from '../../features/serviceSlice';
import parse from 'html-react-parser';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Clock from '../Mobile/icons/clock2.svg'
import { Link } from 'react-router-dom'
import Close from '../Mobile/icons/close.svg';

function ServicePanel({bootData, homeData, servizioId, setServizioId}){

    const id = servizioId;
    
    const [indexCategory, setIndexCategory] = useState(0)

    //orari
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getService("/get-servizio/" +id));
    }, [id]);

    const servizio = useSelector(getServiceState);
    const status = useSelector(getServiceStatus);
    const cart = useSelector(getCart);

    return(
        <Fade className='row justify-content-center'>
        <ToastContainer className="text-wrap" /> 
        {!status 
        ?       
            <div className="col">
            
                <>
                    <Row className='justify-content-center d-flex py-4' style={{backgroundImage:'url('+servizio.copertina+')', backgroundSize: 'cover'}}>
                        <Col className='col-3'></Col>
                        <Col className='d-flex justify-content-center' style={{background: 'rgba(255,255,255,0.7)'}}>{servizio.nome}</Col>
                        <Col className='col-3 d-flex justify-content-center'><Link onClick={() => setServizioId(false)}><img src={Close} /></Link></Col>
                    </Row>
                    <div className="row px-4">
                        <span className="desc-servizio mt-3 pr-4 text-justify">{parse(servizio.descrizione === "" ? "" : servizio.descrizione)}</span>
                    </div>
                    {servizio.orari.giorni && servizio.orari.giorni.length > 0 &&
                        <div className="border-top border-bottom pt-3 pb-3 mx-1">
                            <Row>
                                <Col className="col-auto d-flex align-items-center">
                                    <img src={Clock} width="20" className="mr-3" alt=""/>
                                    <span className="text-schedule">{servizio.orari.label}</span>
                                </Col>
                                <Col className="text-right" onClick={toggle} >
                                    <small className="small">{homeData.testo156} <i className="fas fa-chevron-down"></i></small>
                                </Col>
                            </Row>
                            <Collapse isOpen={isOpen}>
                                <div className="mt-2">
                                    {servizio.orari.giorni && servizio.orari.giorni.map((giorno, index) =>
                                        <Row className="p-1">
                                            <Col>
                                                <span className="font-weight-bold">{giorno.giorno}</span>
                                                <span className="float-right">{giorno.orario}</span>
                                            </Col>
                                        </Row>)}
                                </div>
                            </Collapse>
                        </div>
                    }
                        <div className="testimonial-group mx-0 px-0">
                            {servizio.info !== "1" &&
                                <Row className="text-center py-2 text-dot" style={{ margin: "0px" }}>
                                    {homeData.testo180}
                                </Row>
                            }
                            <Row id='servizi_desktop' className="py-1 text-center my-2" style={{ margin: "0px" }}>
                                {servizio.tipi && servizio.tipi.length > 0 && servizio.tipi.map((tipo, index) =>
                                    <Button key={index} onClick={() => { setIndexCategory(index) }} className={indexCategory === index ? "col btn-secondary text-white w-auto mx-2 cat-serv" : "col btn-light text-white w-auto mx-2 cat-serv" }><span className="m-0">{tipo.nome}</span></Button>
                                )}
                            </Row>
                            <Container style={{ overflow: "auto", height: "65vh" }}>
                                {servizio.tipi[indexCategory] && servizio.tipi[indexCategory].elementi.length > 0
                                    ?
                                    servizio.tipi[indexCategory].elementi.map((elemento, index, they) =>
                                        <div key={index} className="border-bottom mt-3" style={{paddingBottom: they.length - 1 === index ? '50px' : '' }}>
                                            <Row>
                                                <Col>
                                                    <h4 className="font-weight-bold">{elemento.nome}</h4>
                                                    <i className="fas fas-clock"></i>
                                                </Col>
                                                <Col>
                                                    {elemento.sconto > 0 ?
                                                        <>
                                                            <span className="price-tag">{"€ " + elemento.sconto.toFixed(2)}</span>
                                                            <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + elemento.prezzo}</span>
                                                        </>
                                                    :
                                                        <>
                                                            <span className="price-tag">{"€ " + elemento.prezzo}</span>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span className="desc-item">{elemento.descrizione}</span>
                                                </Col>
                                            </Row>
                                            {servizio.info !== "1" //document.cookie.match('connesso') 
                                                ?
                                                <Row className="py-3">
                                                    <Col className="col-6 d-flex">
                                                        {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                            ?
                                                            <span onClick={() => { dispatch(deleteItem(elemento)); }} className="btn-plus ml-0 text-center pointer">-</span>
                                                            :
                                                            <span className="btn-plus ml-0 text-center pointer">-</span>
                                                        }
                                                        <span className="mx-3" style={{ fontSize: "24px" }}>
                                                            {cart.items[cart.items.findIndex(item => item.id === elemento.id)] ?
                                                                cart.items[cart.items.findIndex(item => item.id === elemento.id)].quantity
                                                                :
                                                                0
                                                            }
                                                        </span>
                                                        {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                            ?
                                                            <span onClick={() => dispatch(insertItem(elemento))} className="btn-plus text-center pointer">+</span>
                                                            :
                                                            <span className="btn-plus text-center pointer">+</span>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                            ?
                                                                
                                                            <Button onClick={() => dispatch(addItem(elemento))} className="btn-first w-100">{homeData.testo129}</Button>
                                                            :
                                                            <Button onClick={() => dispatch(removeItem(elemento))} className="btn-light w-100">Cancel</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                :
                                                <></>
                                            }
                                            {cart.items[cart.items.findIndex(item => item.id === elemento.id)] &&
                                                cart.items[cart.items.findIndex(item => item.id === elemento.id)].quantity > 0 ?  
                                                <>        
                                                    {/* ORA E DATE DEI SINGOLI ELEMENTI
                                                    <Row>
                                                        <Col className="col-4">
                                                            {homeData.testo128}*
                                                        </Col>
                                                    </Row>
                                                    <Row className="pb-3">
                                                        <Col className="col-5 pr-0">
                                                            {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                ?
                                                                <input type="time" className="input-service" placeholder="Dalle" onChange={(e) => dispatch(updateOreDalle({ ore: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreDalle:""}/>
                                                                :
                                                                <input type="time" className="input-service" placeholder="Dalle" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreDalle:""} disabled/>
                                                            }   
                                                        </Col>
                                                        <Col className="col pt-2 text-center">-</Col>
                                                        <Col className="col-5 pl-0">
                                                            {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                ?
                                                                <input type="time" className="input-service" placeholder="alle" onChange={(e) => dispatch(updateOreAlle({ ore: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreAlle:""}/>
                                                                :
                                                                <input type="time" className="input-service" placeholder="alle" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].oreAlle:""} disabled/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="col-4">
                                                            Data*
                                                        </Col>
                                                    </Row>
                                                    <Row className="pb-3">
                                                        <Col className="col-5 pr-0">
                                                            {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                ?
                                                                <input type="date" className="input-service" placeholder="Dal" onChange={(e) => dispatch(updateGiorniDal({ giorni: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniDal:""}/>
                                                                :
                                                                <input type="date" className="input-service" placeholder="Dal" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniDal:""} disabled/>
                                                            }
                                                        </Col>
                                                        <Col className="col pt-2 text-center">-</Col>
                                                        <Col className="col-5 pl-0">
                                                            {cart.itemsAdded.findIndex(item => item.id === elemento.id) === -1
                                                                ?
                                                                <input type="date" className="input-service" placeholder="al" onChange={(e) => dispatch(updateGiorniAl({ giorni: e.target.value, elemento: elemento }))} value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniAl:""}/>
                                                                :
                                                                <input type="date" className="input-service" placeholder="al" value={cart.items[cart.items.findIndex(item => item.id === elemento.id)] ? cart.items[cart.items.findIndex(item => item.id === elemento.id)].giorniAl:""} disabled/>
                                                            }
                                                        </Col>
                                                    </Row> */}
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    )
                                    : 
                                    servizio.info === "1"
                                    ?   
                                        <>{/* SERVIZIO PRETTAMENTE INFORMATIVO */}
                                            <Row>
                                                <Col>
                                                    <i className="fas fas-clock"></i>
                                                </Col>
                                                <Col>
                                                    {servizio.sconto > 0 ?
                                                        <>
                                                            <span className="price-tag">{"€ " + parseFloat(servizio.sconto).toFixed(2)}</span>
                                                            <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + servizio.prezzo}</span>
                                                        </>
                                                    :
                                                        <>
                                                            {servizio.prezzo > 0 &&
                                                                <span className="price-tag">{"€ " + servizio.prezzo}</span>
                                                            }
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                    <> {/* SERVIZIO SENZA ELEMENTI CON PRENOTAZIONE*/}
                                        <div key={servizio.id} className="border-bottom mt-3" style={{paddingBottom: servizio.length - 1 === servizio.id ? '50px' : '' }}>
                                            <Row>
                                                <Col>
                                                    <i className="fas fas-clock"></i>
                                                </Col>
                                                <Col>
                                                    {servizio.sconto > 0 ?
                                                        <>
                                                            <span className="price-tag">{"€ " + parseFloat(servizio.sconto).toFixed(2)}</span>
                                                            <span className="price-tag" style={{ textDecoration: 'line-through', fontSize: '15px' }}>{"€ " + servizio.prezzo}</span>
                                                        </>
                                                    :
                                                        <>
                                                            <span className="price-tag">{"€ " + servizio.prezzo}</span>
                                                        </>
                                                    }
                                                </Col>
                                            </Row>
                                            {true /*document.cookie.match('connesso')*/ &&
                                            <>
                                                <Row className="py-3">
                                                    {servizio.ricqua === '1' && //SERVIZIO PRENOTABILE CON QUANTITÀ
                                                    <Col className="col-6 d-flex">
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <span onClick={() => { dispatch(deleteItem(servizio)); }} className="btn-plus ml-0 text-center pointer">-</span>
                                                            :
                                                            <span className="btn-plus ml-0 text-center pointer">-</span>}
                                                        <span className="mx-3" style={{ fontSize: "24px" }}>
                                                            {cart.items[cart.items.findIndex(item => item.id === servizio.id)] ?
                                                                cart.items[cart.items.findIndex(item => item.id === servizio.id)].quantity
                                                                :
                                                                0
                                                            }
                                                        </span>
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <span onClick={() => dispatch(insertItem(servizio))} className="btn-plus text-center pointer">+</span>
                                                            :
                                                            <span className="btn-plus text-center pointer">+</span>
                                                        }
                                                    </Col>
                                                    }
                                                    <Col>
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <Button onClick={() => dispatch(addItem(servizio))} className="btn-first w-100">{homeData.testo129}</Button>
                                                            :
                                                            <Button onClick={() => dispatch(removeItem(servizio))} className="btn-light w-100">Cancel</Button>
                                                        }
                                                    </Col>
                                                </Row>
                                                {/* ORA E DATA DEL SERVIZIO 
                                                <Row>
                                                    <Col className="col-4">
                                                        {homeData.testo128}*
                                                    </Col>
                                                </Row>
                                                <Row className="pb-3">
                                                    <Col className="col-5 pr-0">
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <input type="time" className="input-service" placeholder="Dalle" onChange={(e) => dispatch(updateOreDalle({ ore: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreDalle:""}/>
                                                            :
                                                            <input type="time" className="input-service" placeholder="Dalle" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreDalle:""} disabled/>
                                                        }   
                                                    </Col>
                                                    <Col className="col pt-2 text-center">-</Col>
                                                    <Col className="col-5 pl-0">
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <input type="time" className="input-service" placeholder="alle" onChange={(e) => dispatch(updateOreAlle({ ore: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreAlle:""}/>
                                                            :
                                                            <input type="time" className="input-service" placeholder="alle" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].oreAlle:""} disabled/>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-4">
                                                        Data*
                                                    </Col>
                                                </Row>
                                                <Row className="pb-3">
                                                    <Col className="col-5 pr-0">
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <input type="date" className="input-service" placeholder="Dal" onChange={(e) => dispatch(updateGiorniDal({ giorni: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniDal:""}/>
                                                            :
                                                            <input type="date" className="input-service" placeholder="Dal" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniDal:""} disabled/>
                                                        }
                                                    </Col>
                                                    <Col className="col pt-2 text-center">-</Col>
                                                    <Col className="col-5 pl-0">
                                                        {cart.itemsAdded.findIndex(item => item.id === servizio.id) === -1
                                                            ?
                                                            <input type="date" className="input-service" placeholder="al" onChange={(e) => dispatch(updateGiorniAl({ giorni: e.target.value, elemento: servizio }))} value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniAl:""}/>
                                                            :
                                                            <input type="date" className="input-service" placeholder="al" value={cart.items[cart.items.findIndex(item => item.id === servizio.id)] ? cart.items[cart.items.findIndex(item => item.id === servizio.id)].giorniAl:""} disabled/>
                                                        }
                                                    </Col>
                                                </Row> */}
                                            </>
                                            }
                                        </div>
                                    </>
                                }

                                {/* INFORMATIVA UTENTE NON AUTENTICATO */}
                                {/* {!document.cookie.match('connesso') &&
                                    <>
                                        <Link to={{pathname: '/login', query: { route: 'login' } }} className="p-3 btn w-100 text-white font-weight-bold btn-first">{homeData.testo109}</Link>
                                    </>
                                } */}

                            </Container>
                    </div>
                </>
            
        </div>

        :
            <div style={{position: "absolute", top:"50%"}} className="d-flex justify-content-center h-100">
                <div className="spinner-grow text-warning" style={{backgroundColor: "#C5B39A"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        }
    </Fade>
    )
}

export default ServicePanel;