import React from 'react';
import ServicesPanel from "./ServicesPanel";
import { Col } from 'reactstrap';
import { getBootStatus } from "../../features/bootstrapSlice"
import { useSelector } from 'react-redux';
import purify from 'dompurify';

function FiltersContainer({servizioId, setServizioId, homeData, bootData, statusHome, userData}){

    // const params = useParams();
    // const id = params.id; //id del servizio se ci arrivo da link

    const bootState = useSelector(getBootStatus);

    const gitav = ["1781", "1782", "1780", "1789", "1778", "1783", "1784", "1785", "1786", "1787", "1788", "1790", "1791", "1793", "1792"];

    if(bootState.data.master === "1325" || bootState.data.master === "1539" || bootState.data.master === "1535" || bootState.data.master === "1537" || bootState.data.master === "1540" || bootState.data.master === "1550"){
        var titoloStyle = {fontFamily: "'Lato', sans-serif"}
    }else if(bootState.data.master === "1920") {
        var titoloStyle = {fontFamily: "'Nanum Gothic', sans-serif"}
    }else if(gitav.includes(bootState.data.master)) {
        var titoloStyle = {fontFamily: "'Montserrat', sans-serif"}
    }else if(bootState.data.posto_master.fontwelcome !== "") {
        if(document.getElementById('welcometext'))
        document.getElementById('welcometext').style.cssText += bootState.data.posto_master.fontwelcome;
    }else {
        var titoloStyle = {fontFamily: "'Pacifico', cursive"}
    }

    return(
        <>
        <div className="d-flex pb-2 mt-4 border-bottom" style={titoloStyle} id="welcometext">
        { document.cookie.match('connesso') && userData.data.hasOwnProperty('utente') && !statusHome.loading
            ?
                <h4>{homeData.testo125.replace('[ospite]',userData.data.utente.nome)}</h4>
            :
                <h4>{homeData.testo93}</h4>
        }
        </div>

        {bootState.data.posto_master.meteo == "1" &&
            <div dangerouslySetInnerHTML={{ __html: purify.sanitize(bootState.data.posto_master.meteo_html, { USE_PROFILES: { html: true }, ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }) }}>

            </div>
        }

        <p className="mt-3 mb-0" style={{fontWeight:"bold"}}>{homeData.testo184}</p>
        <div style={{height: '70vh', overflowY: 'auto'}}>
        {homeData.servizi.map((servizio) =>
            servizio.id === servizioId ?
                <a key={servizio.id} className='row p-4 py-2 pointer text-white' onClick={() => setServizioId(servizio.id)} style={{backgroundColor:'#5a6268'}}>
                    <Col className='col-auto'><img src={servizio.icona} width={30}/></Col>
                    <Col>{servizio.nome}</Col>
                </a>
            :
                <a key={servizio.id} className='row p-4 py-2 pointer' onClick={() => setServizioId(servizio.id)}>
                    <Col className='col-auto'><img src={servizio.icona} width={30}/></Col>
                    <Col>{servizio.nome}</Col>
                </a>
        )}
        </div>
        
        {servizioId &&
            <ServicesPanel homeData={homeData} bootData={bootData} servizioId={servizioId} setServizioId={setServizioId}/>
        }
        </>
    )
}

export default FiltersContainer;