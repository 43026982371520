import React, { useEffect, useState } from 'react';
import { Col, Fade } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getPlace, getPlaceState } from '../../features/placeSlice';
import { Link } from 'react-router-dom'
import GoogleMapReact from 'google-map-react';
//import MapsPin from './MapsPin2'

function Itinerario(props) {

    //hooks centro della mappa
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    //gestore del centro della mappa
    const onClickMaps = (elmn) => {
        setLat(parseFloat(elmn.split('-')[0]))
        setLng(parseFloat(elmn.split('-')[1]))
    }

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPlace("/get-itinerario/" + props.match.params.id));
    }, []);
    const selector = useSelector(getPlaceState);

    const apiIsLoaded = (map, maps) => {

        //if(selector.posti) {

            const directionsService = new maps.DirectionsService();
            const directionsRenderer = new maps.DirectionsRenderer();
            directionsRenderer.setMap(map);

            var waypoints = []
            var lat_o
            var long_o
            var lat_d
            var long_d
            
            selector.posti.forEach((posto, i, arr) => {
                if(i === 0){
                    lat_o = parseFloat(posto.latitudine)
                    long_o = parseFloat(posto.longitudine)
                }else if(i === arr.length - 1){
                    lat_d = parseFloat(posto.latitudine)
                    long_d = parseFloat(posto.longitudine)
                }else{
                    var lat_w = parseFloat(posto.latitudine)
                    var long_w = parseFloat(posto.longitudine)
                    
                    var latlong = new maps.LatLng(lat_w, long_w)
                    
                    waypoints.push({"location":latlong});
                }
            });

            const origin = { lat: lat_o, lng: long_o };
            const destination = { lat: lat_d, lng: long_d };
            
            directionsService.route(
            {
                origin: origin,
                destination: destination,
                waypoints: waypoints,
                travelMode: maps.TravelMode.WALKING
            },
            (result, status) => {
                if (status === maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(result);
                var leg = result.routes[0].legs[0];
                const marker = new maps.Marker({
                    position: leg.start_location,
                    icon: '',
                    map: map,
                });
                } else {
                console.error(`error fetching directions ${result}`);
                }
            }
            );
        //}else {

        //}
    };

    return (
        <Fade>
            <div style={{ height: '40vh', width: '100%' }}>
                {selector.lat &&
                    <GoogleMapReact
                        bootstrapURLKeys={{ key:"AIzaSyAUPWWxtpZHGRnLPjFBF1O2XvYFxG4jxjs"}}
                        defaultCenter={{ lat: parseFloat(selector.lat), lng: parseFloat(selector.long) }}
                        center={{ lat: lat !== 0 ? lat : parseFloat(selector.lat), lng: lng !== 0 ? lng : parseFloat(selector.long)}}
                        defaultZoom={12}
                        onChildClick={onClickMaps}
                        mapTypeId='roadmap'
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                    >
                    {/*selector.posti && selector.posti.map((posto, index) => 
                        <MapsPin
                            index={index}
                            key={posto.latitudine + "-" + posto.longitudine}
                            lat={posto.latitudine}
                            lng={posto.longitudine}
                            text={posto.nome}
                            itinerario={posto.nome.split(' ')[0]}
                        />
                    )*/}
                    </GoogleMapReact>
                }
            </div>
            <div className="pt-4 pl-4 pr-4 pb-2 " style={{ backgroundColor: "#fff" }}>
            {selector.posti && selector.posti.map((posto, index) => 
                <Link to={{pathname: "/place/" + posto.id, query: props.location.query, state: { prevPath: '/itinerary/'+props.match.params.id, page: 'itinerary' } }} key={index} className="row card-list">
                    <Col className="border-bottom p-0 ml-2 mr-2">
                        <div className="card-img w-auto" style={{ backgroundImage: `url('${posto.photos[0]}')` }}>
                            <div className="row m-2 rate-box">
                                <div className="col-auto pr-1 mx-auto fas fa-star"></div>
                                <div className="col-auto p-0">N.D</div>
                            </div>
                            <div className="row m-2 rate-box">
                                <div className="col-auto pr-1 mx-auto fas fa-heart"></div>
                                <div className="col-auto p-0">0</div>
                            </div>
                        </div>
                        <h4 className="card-text mt-2 mb-0">{posto.nome}</h4>
                    </Col>
                </Link>
            )}
            </div>
        </Fade>
    )
}

export default Itinerario