import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Fade, Row, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import Clock from './icons/clock3.svg'
import Clean from './icons/close.svg'
import { useSelector, useDispatch } from 'react-redux';
import { getHomeState } from '../../features/homepageSlice';
import { getSearch, getSearchState } from '../../features/searchSlice';
import { setRecent, getRecentState } from '../../features/recentSlice';

function Searchbox(props) {

    const [keyword, setKeyword] = useState('')

    const homeData = useSelector(getHomeState)
    const risultati = useSelector(getSearchState)
    const recenti = useSelector(getRecentState)

    useEffect(() => {
        if (document.getElementById("search"))
            document.getElementById("search").focus()
    }, []);

    const dispatch = useDispatch();

    const search = (parola) => {
        setKeyword(parola)
        if(parola !== "")
            dispatch(getSearch("/cerca/"+parola.toLowerCase()));
    }

    const clean = () => {
        document.getElementById("search").value = "";
        setKeyword("")
    }

    return (
        <>
            <Fade>
                <div className="search-box">
                    <Row className="pb-5">
                        <div className="btn-times">
                            <Link onClick={() => props.close()} className="btn-times-icon d-block text-center">&times;</Link>
                        </div>
                    </Row>
                    <Row className="mt-4 mx-auto">
                        <Col>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span onClick={(e) => clean()} className="input-group-text bg-white border-0 border-right" id="basic-addon1">
                                        <img src={Clean} alt="" />
                                    </span>
                                </div>
                                <input type="text" id="search" className="form-control border-0" onChange={(e) => search(e.target.value)} placeholder={homeData.testo115}/>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ height:'100%', overflowY:'auto', paddingBottom: '70vh' }} >
                        {!risultati.loading && risultati.data.hasOwnProperty('posti') && keyword !== "" &&
                        <>
                            <Row className="pt-2 pl-2 pr-2 pb-0">
                                <Col className="ml-3">
                                    <span className="text-search">{homeData.testo143}</span>
                                </Col>
                                <Col className="text-right mr-3">
                                    {/*<span style={{ color: "rgb(198, 179, 154)", fontWeight: "600" }}>View All</span>*/}
                                </Col>
                            </Row>
                            <Container className="p-2 pl-1 text-justify">
                                {risultati.data.hasOwnProperty('posti') && risultati.data.posti.slice(0,7).map((posto, index) =>
                                    <Link key={index} to={{pathname: "/place/" + posto.id, state: { prevPath: '/'} }} onClick={() => dispatch(setRecent({nome: posto.nome, id: posto.id}))}>
                                    <Row className="pl-3">
                                        <Col className="mb-3 d-flex">
                                            <img src={Clock} className="far fa-clock mr-4" width="20" alt=""/>
                                            <span className="align-items-center"><b className="text-capitalize">{posto.nome}</b></span>
                                        </Col>
                                    </Row>
                                    </Link>
                                )}

                                {risultati.data.posti.length === 0 &&
                                    <>{homeData.testo144}</>
                                }
                            </Container>
                        </>
                        }
                        {risultati.loading &&
                            <div className="text-center">
                                <Spinner color="warning" />
                            </div>
                        }
                        <Row className="pt-2 pl-2 pr-2 pb-0">
                            <Col className="mt-3 ml-3">
                                <span className="text-search">{homeData.testo113}</span>
                            </Col>
                            <Col className="text-right mt-3 mr-3">
                                {/*<span style={{ color: "rgb(198, 179, 154)", fontWeight: "600" }}>View All</span>*/}
                            </Col>
                        </Row>
                        <Row className="p-2 pl-1 text-justify search-cat-box">
                            {homeData.categorie.map((categoria, index) =>
                                <Link key={index} to={{pathname: '/viewall', query: { item: { items:categoria.posti, subcat:categoria.subcat }}, state: { prevPath: '/'} }} >
                                    <Button className="search-cat">
                                        {categoria.nome}
                                    </Button>
                                </Link>
                            )}
                        </Row>
                        <Row className="pl-2 mb-2">
                            <Col className="m-2 mt-3 ml-3">
                                <span className="text-search">{homeData.testo114}</span>
                            </Col>
                        </Row>
                        <Container>
                        {recenti.map((recente, index) =>
                            <Link key={index} to={{pathname: "/place/" + recente.id, state: { prevPath: '/'} }} onClick={() => dispatch(setRecent({nome: recente.nome, id: recente.id}))}>
                                <Row className="pl-3">
                                    <Col className="mb-3 d-flex">
                                        <img src={Clock} className="far fa-clock mr-4" width="20" alt=""/>
                                        <span className="align-items-center"><b className="text-capitalize">{recente.nome}</b></span>
                                    </Col>
                                </Row>
                            </Link>
                        )}   
                        </Container>
                    </div>
                </div>
            </Fade>
        </>
    )
}

export default Searchbox