import React from 'react';

function Categorielist({homeData, checkFilter}) {

    return (
        <>
        <div className="ml-0 mt-2 ">
            {homeData.categorie.map((categoria, index) =>{
                return(
                    <div className="mr-3" key={index}>
                        <input className="mt-3" id={categoria.nome} type="checkbox" value={categoria.nome} onClick={e => checkFilter(e.target.value)} style={{display:"none"}}/>
                        <label htmlFor={categoria.nome} style={{fontSize: "18px"}} className="ml-2 mb-1 pointer"><img src={categoria.icona} className="pr-2 mb-1" height="25" alt=""/>{categoria.nome}</label>
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default Categorielist