
import PlaceCard from "./PlaceCard";

function PlacesCardsContainer({ homeData, places, geo }){

    return(
        <div className="card-columns mt-4 places-container">
            {places.map((posto, index) =>{
            return(
                    <PlaceCard index={index} posto={posto} geo={geo} homeData={homeData} />
                )
            })}
        </div>
    )
}

export default PlacesCardsContainer;